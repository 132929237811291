import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PurchaseCreditDialogComponent } from './purchase-credit-dialog/purchase-credit-dialog.component';
import { RazorpayPaymentService } from 'src/app/services/razorpay-payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditPurchaseHistoryComponent } from './credit-purchase-history/credit-purchase-history.component';
import PaginationOptions from 'src/app/models/PaginationOptions';
import * as moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  Global = Global;
  upcomingBirthdaysList: any[] = [];
  curruntYear = moment().year();
  curruntMonth = moment().month() + 1;
  curruntDate = moment().date();
  tab: 'Financial Year' | 'Date Range' = 'Financial Year';
  upcomingWorkAnniversaryList: any[] = [];
  paginationOptionsExpiring: PaginationOptions = Global.resetPaginationOption();
  latestAnnouncementData: any[] = [];
  dashboardCTCData: any[] = [];
  noticeData: any[] = [];
  lastTenFnf: any[] = [];
  triggerScrollEvent$?: Subscription;
  empLeaveList: any[] = [];
  filterTimeChartMonths: any[] = [];
  ctcLoading: boolean = false;
  classArray:any[] = ['border-yello', 'border-green', 'border-red'];
  currentLanguage$?: Subscription;
  translations: any;
  todayDate: Date;
  todayTomoClass: any;
  newJoinedEmployees: number = 0;
  leaveList: any;
  selectedMonth: string = '';
  today: Date = new Date();
  totalEmployees: any;
  lastTenEmp:any[] = []
  leaveApplication: number = 0;
  company_logo: string = '';
  curr_month: any;
  dateNow: any;
  dashboardFinancialData: {
    items: any[];
    _id: string;
    challans: any[];
    hold_salary_emps: any[];
    bank_instructions: any[];
    total_fy_pf_amount_1: number;
    total_fy_esic_amount_1: number;
    total_fy_hold_salary_1: number;
    total_fy_pending_salary_1: number;
    total_fy_pf_amount_2: number;
    total_fy_esic_amount_2: number;
    total_fy_hold_salary_2: number;
    total_fy_pending_salary_2: number;
  } = {
    items: [],
  _id: '',
  challans: [],
  hold_salary_emps: [],
  bank_instructions: [],
  total_fy_pf_amount_1: 0,
  total_fy_esic_amount_1: 0,
  total_fy_hold_salary_1: 0,
  total_fy_pending_salary_1: 0,
  total_fy_pf_amount_2: 0,
  total_fy_esic_amount_2: 0,
  total_fy_hold_salary_2: 0,
  total_fy_pending_salary_2: 0
  };

  curr_year: any;
  dashboardChartData: any;
  isLoading!: boolean;
  filterForm: FormGroup = new FormGroup({
    emp_date_filter: new FormControl('', Validators.required),
    challan_date_filter: new FormControl('', Validators.required),
    challan_date_from: new FormControl('', Validators.required),
    challan_date_to: new FormControl('', Validators.required),
    financial_date_filter: new FormControl('', Validators.required),
    ctc_date_filter: new FormControl('', Validators.required),
  });
  lang!: string;
  constructor(
    private langService: LanguageTranslateService,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private router: Router,
    private dialog: MatDialog,
    private titleService:Title,
    private cdr:ChangeDetectorRef,
    private paymentService: RazorpayPaymentService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.upcomingBirthdaysList = [];
    this.filterTimeChartMonths = [];
    this.todayDate = new Date();
    authService.com_logo$.subscribe((res: any) => {
      this.company_logo = res;
    });
    // this.dateNow = new Date();
    this.curr_month = this.today.getMonth().toString();

    this.curr_year = this.todayDate.getFullYear();
    // this.selectedMonth = Global.getMonthRange[parseInt(this.attendance_month)];
    this.getDashboardCTCData();
  }
  public barChartPlugins = [];
  public barChartLegend = true;
  public barChartLegend2 = true;
  financialYearOptions: any[] = [];

  async ngOnInit() {
    this.spinner.show();
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(
      async () => {
        return new Promise<boolean>(async (resolve, reject) => {
          await this.langService
            .loadTranslations('home', 'HomeComponent')
            ?.then((res: any) => {
              this.translations = res;
              resolve(true);
            })
            .catch((err: any) => {
              console.error(`Failed to load translations`, err);
              reject(false);
            });
        });
      }
    );
    this.activatedRoute.paramMap.subscribe((params: any) => {
      this.isLoading = true;
      // this.spinner.show();
      this.lang = params.get('lang');
      // this._adapter.setLocale(this.lang);
      setTimeout(() => {
        this.isLoading = false;
        // this.spinner.hide();
      }, 1000);
    });

    let years = [
      new Date().getFullYear() - 2,
      new Date().getFullYear() - 1,
      new Date().getFullYear(),
      new Date().getFullYear() + 1,
    ];
    for (let i = 0; i < years.length - 1; i++) {
      let combinedYear = {
        label: `${years[i]}-${years[i + 1].toString().slice(-2)}`,
        value: `${years[i]}-${years[i + 1].toString()}`,
      };
      this.financialYearOptions.push(combinedYear);      
    }

    this.filterForm.patchValue({
      ctc_date_filter: this.financialYearOptions[1]?.value,
      emp_date_filter: this.financialYearOptions[1]?.value,
      financial_date_filter: this.financialYearOptions[1]?.value,
      challan_date_filter: this.financialYearOptions[1]?.value,
    });
    this.getNewJoinedEmployees();
    this.getLeaveList();
    this.getUpcomingBirthdays();
    this.getUpcomingWork();
    this.getTotalEmployee();
    this.latestAnnouncement();
    // this.latestNotice();
    this.getNoticeExpiring();
    await this.getDashboardChartData(true);
    await this.getDashboardCTCData();
    await this.getDashboardFinancialData();
  }

  public barChartData: ChartData<any> = {
    labels: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],

    datasets: [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        label: 'Active Employee',
        backgroundColor: '#51F1E6',
        borderColor: '#51F1E6',
        order: 0,
        type: 'line',
        pointBackgroundColor: '#fff',
        pointBorderColor: '#51F1E6',
      },
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        label: 'New Employee',
        backgroundColor: 'rgba(22,163,183)',
        borderColor: 'rgba(22,163,183)',
        order: 1,
        type: 'line',
        pointBackgroundColor: '#fff',
        pointBorderColor: 'rgba(22,163,183)',
      },
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        label: 'Exit Employee',
        backgroundColor: '#07515A',
        borderColor: '#07515A',
        order: 2,
        type: 'line',
        pointBackgroundColor: '#fff',
        pointBorderColor: '#07515A',
      }
     
    ],
  };
  public barChartType: ChartType = 'bar';
  public barChartData2: ChartData<any> = {
    labels: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],

    datasets: [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        label: 'Budget',
        backgroundColor: '#51F1E6',
        borderColor: '#51F1E6',
        pointBackgroundColor: '#fff',
        pointBorderColor: '#51F1E6',
        order: 1,
      },
      {
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        label: 'Actual Processed Amount ',
        backgroundColor: '#07515A',
        borderColor: '#07515A',
        pointBackgroundColor: '#fff',
        pointBorderColor: '#07515A',
        order: 2,
      },
      // {
      //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      //   label: 'Exit Employee',
      //   backgroundColor: '#07515A',
      //   order: 1,
      // },
      // {
      //   data: [0, 0, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0],
      //   label: 'Active Employee',
      //   backgroundColor: '#51F1E6',
      //   borderColor: '#51F1E6',
      //   order: 0,
      //   type: 'line',
      //   // pointBackgroundColor: '#fff',
      //   // pointBorderColor: '#51F1E6',
      // },
    ],
  };

  public barChartOptions2: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    // responsive: false,
    aspectRatio: 1.5,
    // animation: false,
    scales:{
      y: {
        beginAtZero: true, // Ensure the Y-axis starts at 0
      }
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: false,
    aspectRatio: 1.5,
    animation: false,
    scales:{
      y: {
        beginAtZero: true, // Ensure the Y-axis starts at 0
      }
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };
  async getDashboardCTCData() {
    try {
      this.barChartData2.datasets[0].data = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      this.barChartData2.datasets[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.ctcLoading = true;
      let keys = this.filterForm.value.ctc_date_filter.split('-');

      let payload = {
        ctc_date_filter: JSON.stringify({
          from: keys[0],
          to: keys[1],
        }),

        financial_year_end: JSON.parse(
          localStorage.getItem('hrmlix-ind-companyuser-details') as any
        )?.preference_settings?.financial_year_end,
      };
      let res = await this.dashboardService.getDashboardCTCData(payload);
      if (res.status !== 'success') throw res;
      this.dashboardCTCData = res.dashboard_data;
      let months = this.getCurrFinancialYearMonths();  
          
      let items: any = [];
      months.forEach((month) => {
        items.push({
          month: month,
          logs: res.dashboard_data.companies_monthly_data_logs.find(
            (challan: any) => +challan._id == month?.index
          ),
          report: res.dashboard_data.employee_monthly_reports.find(
            (emp: any) => +emp._id == month?.index
          ),
        });
      });
      this.barChartData2.datasets[0].data = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      this.barChartData2.datasets[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      if (items?.length) {
        
        this.barChartData2.datasets[0].data = [];
        this.barChartData2.datasets[1].data = [];
        this.barChartData2.labels = [];

        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          this.barChartData2?.labels?.push(item?.month?.sf);
          this.barChartData2?.datasets[0]?.data?.push(item?.logs?.total_budget ?? 0);
          this.barChartData2?.datasets[1]?.data?.push(item?.report?.total_gross_salary ?? 0);
        }     
           
      }
      this.ctcLoading = false;
    } catch (err: any) {
      // this.toastr.error(err.message || err);
    }
  }
  isCtcGraphLoading: boolean = false;

  isBarChart2Loading: boolean = false;
  async getDashboardChartData(isEmployeeChart: boolean = false) {
    try {
      if (isEmployeeChart) {
        this.barChartData.datasets[0].data = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ];
        this.barChartData.datasets[1].data = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ];
        this.barChartData.datasets[2].data = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ];
        this.isBarChart2Loading = true;
      }

      let emp_keys: any[] = this.filterForm.value.emp_date_filter.split('-');
      let challan_keys = this.filterForm.value.challan_date_filter.split('-');

      if (this.tab == 'Date Range') {
        challan_keys = [
          this.filterForm.value.challan_date_from,
          this.filterForm.value.challan_date_to,
        ];
      }

      let payload = {
        challan_filter_type: this.tab.split(' ').join('_').toLowerCase(),
        emp_date_filter: JSON.stringify({
          from: emp_keys[0],
          to: emp_keys[1],
        }),
        challan_date_filter: JSON.stringify({
          from: challan_keys[0],
          to: challan_keys[1],
        }),
        financial_year_end: JSON.parse(
          localStorage.getItem('hrmlix-ind-companyuser-details') as any
        )?.preference_settings?.financial_year_end,
      };
      let res = await this.dashboardService.getDashboardChartData(payload);
      if (res.status !== 'success') throw res;

      res.dashboard_data.pf_precent =
        ((res?.dashboard_data?.pf_on_time_payments || 0) /
          (res?.dashboard_data?.total_challans_count_pf || 0)) *
          100 || 0;
      res.dashboard_data.esic_precent =
        ((res?.dashboard_data?.esic_on_time_payments || 0) /
          res?.dashboard_data?.total_challans_count_esic || 0) * 100 || 0;
      this.dashboardChartData = res?.dashboard_data;
      let employees = res.dashboard_data?.employees;
      // this.barChartData2.datasets = [
      //   {
      //     data: [0,0,0,0,0,0,0,0,0,0,0,0],
      //     label: 'New Employee',
      //     backgroundColor: 'rgba(22,163,183)',
      //     order: 1,
      //   },
      //   {
      //     data: [0,0,0,0,0,0,0,0,0,0,0,0],
      //     label: 'Exit Employee',
      //     backgroundColor: '#07515A',
      //     order: 1,
      //   },
      //   {
      //     data: [0,0,0,0,0,0,0,0,0,0,0,0],
      //     label: 'Active Employee',
      //     backgroundColor: '#51F1E6',
      //     borderColor: '#51F1E6',
      //     order: 0,
      //     type: 'line',
      //     pointBackgroundColor: '#fff',
      //     pointBorderColor: '#51F1E6',
      //   },
      // ];
      this.barChartData.datasets[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.barChartData.datasets[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.barChartData.datasets[2].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let months = this.getCurrFinancialYearMonths();
      let items:any = [];

      months.forEach((m:any,i:any)=>{
        items.push({
          month:m,
          employee:res.dashboard_data?.employees?.find((emp:any)=>{
            return emp?.wage_month == m?.index
          })
        })
      })
      
      if (items?.length) {
        
        this.barChartData.datasets[0].data = [];
        this.barChartData.datasets[1].data = [];
        this.barChartData.datasets[2].data = [];
        this.barChartData.labels = [];             
        for (let i = 0; i < items.length; i++) {
          const item = items[i] ?? [];
          
          // this.barChartData.labels.push(
     
          //   Global.monthMaster.find(
          //     (month) => month.index == employee.wage_month
          //   )?.sf
          // );
          this.barChartData?.labels?.push(item?.month?.sf)
          this.barChartData?.datasets[0]?.data?.push(item?.employee?.active_employees?.length ?? 0);
          this.barChartData?.datasets[1]?.data?.push(item?.employee?.pending_employees?.length ?? 0);
          this.barChartData?.datasets[2]?.data?.push(item?.employee?.inactive_employees?.length ?? 0);

          
        }
      }
      this.isBarChart2Loading = false;
    } catch (err: any) {
      // this.toastr.error(err.message || err);
      // console.log(err, 'error');
    }
  }
  getDateFromToMaxValidation(dateFrom: any) {
    if (dateFrom) {
      if (
        dateFrom?.split('-')[0] == new Date().getFullYear() &&
        dateFrom?.split('-')[1] == '0' + (new Date().getMonth() + 1)
      ) {
        return;
      } else if (dateFrom?.split('-')[0] == new Date().getFullYear()) {
        let year = dateFrom?.split('-')[0];
        return year + '-0' + this.curruntMonth + '-' + this.curruntDate;
      } else if (
        dateFrom?.split('-')[0] == new Date().getFullYear() - 1 &&
        dateFrom?.split('-')[1] > '0' + (new Date().getMonth() + 1)
      ) {
        let year = dateFrom?.split('-')[0];

        return +year + 1 + '-0' + this.curruntMonth + '-' + this.curruntDate;
      }
      let year = dateFrom?.split('-')[0];
      let month = dateFrom?.split('-')[1];
      let date = dateFrom?.split('-')[2];
      return +year + 1 + '-' + month + '-' + date;
    }
    return;
  }
  getCurrFinancialYearMonths() {
    let financialMonths = [];
    let financial_year_end = new Date(
      JSON.parse(
        localStorage.getItem('hrmlix-ind-companyuser-details') as any
      )?.preference_settings?.financial_year_end
    );

    for (let i = 0; i < 12; i++) {
      financialMonths.push(
        Global.monthMaster.find(
          (month) =>
            month.index ==
            new Date(
              financial_year_end.getFullYear(),
              financial_year_end.getMonth() + i,
              +1
            ).getMonth()
        )
      );
    }
    return financialMonths;
  }
  formatDate(dateString: string): { day: string, month: string } {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short' } as const;
    const formattedDate = date.toLocaleDateString('en-US', options);
    const [month, day] = formattedDate.split(' ');
    return { day, month };
  }
  getEmpDate(date: any) {
    let empDate = new Date(date);
    const millisecondsInDay = 86400000;
    const tomorrowDate = new Date();
    tomorrowDate.setTime(tomorrowDate.getTime() + millisecondsInDay);
    if (
      empDate.getDate() === this.todayDate.getDate() &&
      empDate.getMonth() === this.todayDate.getMonth()
    ) {
      return (this.todayTomoClass = 'Today');
    } else if (
      empDate.getDate() === tomorrowDate.getDate() &&
      empDate.getMonth() === tomorrowDate.getMonth()
    ) {
      return (this.todayTomoClass = 'Tomorrow');
    } else {
      this.todayTomoClass = '';
      return Global.formatedDate(date);
    }
  }
  async getDashboardFinancialData() {
    // this.spinner.show();
    try {
      let keys = this.filterForm.value.financial_date_filter.split('-');

      let payload = {
        financial_date_filter: JSON.stringify({
          from: keys[0],
          to: keys[1],
        }),

        financial_year_end: JSON.parse(
          localStorage.getItem('hrmlix-ind-companyuser-details') as any
        )?.preference_settings?.financial_year_end,
      };
      let res = await this.dashboardService.getDashboardFinancialData(payload);
      if (res.status !== 'success') throw res;
      this.dashboardFinancialData = res.dashboard_data;
      let months = this.getCurrFinancialYearMonths();
      this.dashboardFinancialData.items = [];
      months.forEach((month) => {
        this.dashboardFinancialData.items.push({
          month: month?.description,
          challan_data: res.dashboard_data.challans.find(
            (challan: any) => +challan._id == month?.index
          ),
          hold_salary: res.dashboard_data.hold_salary_emps.find(
            (emp: any) => +emp._id == month?.index
          ),
          unpaid_salary: res.dashboard_data.bank_instructions.find(
            (bank_instruction: any) => +bank_instruction._id == month?.index
          ),
        });
      });
      this.dashboardFinancialData.total_fy_pf_amount_1 =
        this.dashboardFinancialData.items
          .slice(0, 6)
          .reduce(
            (t_val: number, c_val: any) =>
              (+t_val || 0) + (+c_val?.challan_data?.total_pf_amount || 0)
          );
      this.dashboardFinancialData.total_fy_esic_amount_1 =
        this.dashboardFinancialData.items
          .slice(0, 6)
          .reduce(
            (t_val: number, c_val: any) =>
              (+t_val || 0) + (+c_val?.challan_data?.total_esic_amount || 0)
          );
      this.dashboardFinancialData.total_fy_hold_salary_1 =
        this.dashboardFinancialData.items
          .slice(0, 6)
          .reduce(
            (t_val: number, c_val: any) =>
              (+t_val || 0) + (+c_val?.hold_salary?.total_gross_salary || 0)
          );
      this.dashboardFinancialData.total_fy_pending_salary_1 =
        this.dashboardFinancialData.items
          .slice(0, 6)
          .reduce(
            (t_val: number, c_val: any) =>
              (+t_val || 0) + (+c_val?.unpaid_salary?.total_gross_earning || 0)
          );
      this.dashboardFinancialData.total_fy_pf_amount_2 =
        this.dashboardFinancialData.items
          .slice(6, 12)
          .reduce(
            (t_val: number, c_val: any) =>
              (+t_val || 0) + (+c_val?.challan_data?.total_pf_amount || 0)
          );
      this.dashboardFinancialData.total_fy_esic_amount_2 =
        this.dashboardFinancialData.items
          .slice(6, 12)
          .reduce(
            (t_val: number, c_val: any) =>
              (+t_val || 0) + (+c_val?.challan_data?.total_esic_amount || 0)
          );
      this.dashboardFinancialData.total_fy_hold_salary_2 =
        this.dashboardFinancialData.items
          .slice(6, 12)
          .reduce(
            (t_val: number, c_val: any) =>
              (+t_val || 0) + (+c_val?.hold_salary?.total_gross_salary || 0)
          );
      this.dashboardFinancialData.total_fy_pending_salary_2 =
        this.dashboardFinancialData.items
          .slice(6, 12)
          .reduce(
            (t_val: number, c_val: any) =>
              (+t_val || 0) + (+c_val?.unpaid_salary?.total_gross_earning || 0)
          );
          this.spinner.hide();
    } catch (err: any) {
      this.spinner.hide();
      this.toastr.error(err.message || err);
    }
  }
  routeToEmpPersonalDetails(id:any){
    this.router.navigate([this.lang, 'employees', id, 'view','personal-details'])
  }
  getEmmApprDate(date: any) {
    let empDate = new Date(date);
    const millisecondsInDay = 86400000;
    const tomorrowDate = new Date();
    tomorrowDate.setTime(tomorrowDate.getTime() + millisecondsInDay);
    if (
      empDate.getDate() === this.todayDate.getDate() &&
      empDate.getMonth() === this.todayDate.getMonth()
    ) {
      return (this.todayTomoClass = 'Today');
    } else if (
      empDate.getDate() === tomorrowDate.getDate() &&
      empDate.getMonth() === tomorrowDate.getMonth()
    ) {
      return (this.todayTomoClass = 'Tomorrow');
    } else {
      this.todayTomoClass = '';
      return `${Global.getDateMonth(date)} ${this.curr_year}`;
    }
  }
  getlastEmpDate(date: any) {
    let empDate = new Date(date);
    const millisecondsInDay = 86400000;
    const tomorrowDate = new Date();
    tomorrowDate.setTime(tomorrowDate.getTime() + millisecondsInDay);
    // if (
    //   empDate.getDate() === this.todayDate.getDate() &&
    //   empDate.getMonth() === this.todayDate.getMonth()
    // )
   
    // {
    //   this.todayTomoClass = '';
    //   return `${Global.getDateMonth(date)} ${this.curr_year}`;
    // }
    this.todayTomoClass = '';
    return `${Global.getDateMonth(date)} ${this.curr_year}`;
  }
  routeToannounce() {
    this.lang = this.langService.getLangLocalStorage() ?? 'en';
    this.router.navigate([this.lang, 'announcement-list']);
  }
  getNewJoinedEmployees() {
    return new Promise((resolve, reject) => {
      this.dashboardService
        .newJoinedEmployees({})
        .then((res: any) => {
          this.newJoinedEmployees = res?.data;
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  getLeaveList() {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.dashboardService
        .getLeaveList({
          pageno: 1,
          status: 'approved',
          perpage: 10,
          year: date.getFullYear(),
        })
        .then((res: any) => {
          this.empLeaveList = res?.data?.docs;
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  routeToNotice() {
    this.lang = this.langService.getLangLocalStorage() ?? 'en';
    this.router.navigate([this.lang, 'notice']);
  }
  routeToAppraisal() {
    this.lang = this.langService.getLangLocalStorage() ?? 'en';
    this.router.navigate([this.lang, 'appraisal', 'apply-appraisal']);
  }
  navigateToEmployees(filter: any) {
    const queryParams: NavigationExtras = {
      queryParams: { filter: filter },
    };

    this.router.navigate(
      [this.lang, 'employees', 'employee-listing'],
      queryParams
    );
  }
  routeToPfEsic(pf_esic:string){    
    this.router.navigate([this.lang,'reports','compliance','challan'],
     {queryParams:{filter:pf_esic}}
    )
  }
  routeToPage(module: string, page: string) {
    this.router.navigate([this.lang, module, page]);
  }
  routeToPaymntInst(){
    this.router.navigate([this.lang,'payrolls','payment-instruction'])
  }
  getUpcomingBirthdays() {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.dashboardService
        .getUpcomingBirthdays({ page: 1, perpage: 10 })
        .then((res: any) => {
          this.upcomingBirthdaysList = res?.data?.docs;
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  getUpcomingWork() {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.dashboardService
        .getUpcomingWorkAnni({ page: 1, perpage: 10 })
        .then((res: any) => {
          this.upcomingWorkAnniversaryList = res?.data?.docs;
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }

  getTotalEmployee() {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.dashboardService
        .getTotalEmployes({})
        .then((res: any) => {
          if (res?.status === 'success') {
            this.totalEmployees = res?.dashboard_data ?? [];
            this.cdr.detectChanges();
            this.lastTenEmp = res?.dashboard_data?.active_employees ?? [];
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }

  latestAnnouncement() {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.dashboardService
        .latestAnnouncement({ page: 1, perpage: 5 })
        .then((res: any) => {
          this.latestAnnouncementData = res?.data?.docs ?? [];
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  getNoticeExpiring({
    page = <any>null,
    loading = <boolean>true,
    filter = <any>null,
  } = {}) {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.dashboardService
        .getNotice({
          pageno: 1,
          perpage: 5,
          type: 'expiring',
        })
        .then((res: any) => {
          if (res?.status === 'success') {
            const data: any = res?.data ?? [];
            this.noticeData = res?.data?.docs ?? [];
            this.paginationOptionsExpiring = {
              page: data?.page,
              limit: data?.limit,
              hasNextPage: data?.hasNextPage,
              hasPrevPage: data?.hasPrevPage,
              nextPage: data?.nextPage,
              prevPage: data?.prevPage,
              totalDocs: data?.totalDocs,
              totalPages: data?.totalPages,
              pagingCounter: data?.pagingCounter,
            };
          }

          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  latestNotice() {
    const date = new Date();
    return new Promise((resolve, reject) => {
      this.dashboardService
        .latestNotice({ type: 'expiring', perpage: 10 })
        .then((res: any) => {
          this.noticeData = res?.data?.docs ?? [];

          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }

  async fetchCreditSettings() {
    try {
      this.spinner.show();
      const res = await this.paymentService.getCreditSettingsValue();
      if (
        res.settings_vaule?.credit_amount &&
        res.settings_vaule?.credit_value
      ) {
        const creditAmountPerToken =
          parseFloat(res.settings_vaule?.credit_amount || 0) /
          parseFloat(res.settings_vaule?.credit_value || 0);
        const vatPercentage = parseFloat(res.settings_vaule?.gst_amount || 0);
        this.openPurchaseCreditDialog({
          creditAmountPerToken,
          vatPercentage: vatPercentage,
        });
        this.spinner.hide();
      }
    } catch (err: any) {
      this.spinner.hide();
      this.toastr.error(err?.message || err || 'Something went wrong');
    }
  }

  openPurchaseCreditDialog(data: any) {
    const dialogRef = this.dialog.open(PurchaseCreditDialogComponent, {
      data,
      minWidth: '450px',
      width: 'min-content',
      height: 'fit-content',
      maxHeight: '90vh',
      hasBackdrop: true,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res === true) {
        this.getTotalEmployee();
      }
    });
  }

  openPaymentHistoryDialog() {
    const dialogRef = this.dialog.open(CreditPurchaseHistoryComponent, {
      data: {},
      minWidth: '450px',
      width: 'min-content',
      height: 'fit-content',
      maxHeight: '90vh',
      hasBackdrop: true,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res: any) => {});
  }

  routeToanncDetails(id: any) {
    this.router.navigate([this.lang, 'announcement-detail', id]);
  }
  routeTonoticeDetails(id: any, document_id: any) {
    this.router.navigate([this.lang,'employees',id,'edit','documents','user',document_id,]);
  }
  ngOnDestroy(): void {
    this.currentLanguage$?.unsubscribe();
    this.triggerScrollEvent$?.unsubscribe();
  }
}
