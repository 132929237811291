<div class="app-content leave-cms">
    <app-menu-sidebar></app-menu-sidebar>

    <div class="app-header topfinance-nav">
        <ul>
            <li [class.active]="isActive('invoices')" (click)="routerChange('invoices')">{{translations?.invoices}}</li>
            <li [class.active]="isActive('purchase-history')" (click)="routerChange('purchase-history')">{{translations?.purchaseHistory}}</li>
            <li [class.active]="isActive('consumption-history')" (click)="routerChange('consumption-history')">{{translations?.consumptionHistory}}</li>
            <!-- <li [class.active]="isActive('advance-request')" (click)="routerChange('advance-request')">{{translations?.advanceRequest}}</li>
            <li [class.active]="isActive('work-from-home')" (click)="routerChange('work-from-home')">{{translations?.workFromHome}}</li> -->
          </ul>

          <!-- <app-language></app-language> -->
          <app-profile></app-profile>
    </div>
  
    <router-outlet></router-outlet>

</div>
