import { Component, Input, OnInit } from '@angular/core';
import { EmployeeProfileService } from 'src/app/services/employee-profile.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-print-payslip',
  templateUrl: './print-payslip.component.html',
  styleUrls: ['./print-payslip.component.css']
})
export class PrintPayslipComponent implements OnInit{
  Global = Global
  @Input() empData: any = null
  @Input() tempDetails: any = null
  @Input() wageMonth: any = null
  @Input() wageYear: any = null
  company_name:any;
  comLogo:any;
  payslip_logo:any
  comUserData:any

  constructor(
    private empProfileService:EmployeeProfileService
  ){
    console.log(this.empData,'emp data');
    this.comUserData = localStorage.getItem('hrmlix-ind-companyuser-user');
    this.comLogo = JSON.parse(this.comUserData)?.com_logo;
    console.log(this.comLogo,'com');
    
    this.company_name = localStorage.getItem('hrmlix-ind-companyuser-details');
    this.company_name = JSON.parse(this.company_name)?.details?.establishment_name;

  }
  
  ngOnInit(): void {
    this.singleViewImage(this.tempDetails?.company_logo);
    console.log(this.tempDetails?.company_logo,'emp datass');
    
  }

  singleViewImage(fileName: any) {
    this.empProfileService.singleViewImage({ 'image_path': fileName }).then((res: any) => {

        if (res.status == 'success') {
          this.payslip_logo = res?.image;
            // Global.imageViewer(Global.ASSET_URL + res.image)

        }
    })
}
}
