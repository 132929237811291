import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.css']
})
export class ViewImageComponent implements OnInit{
lang:any = 'en'

  currentLanguage$?:Subscription;
  translations:any;
  imagePath!: SafeResourceUrl;

  constructor(
    private dialogRef:MatDialogRef<ViewImageComponent>,
    private langService:LanguageTranslateService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){

    this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(data)
  }

  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      this.lang = this.langService.getLangLocalStorage();
       return new Promise<boolean>(async (resolve, reject) => {
         await this.langService.loadTranslations('profile', 'employeeModal')?.then((res: any) => {
           this.translations = res;
          
           resolve(true);
         }).catch((err: any) => {
           console.error(`Failed to load translations`, err);
           reject(false);
         });
       })
     });
  }
  closeDialog(){
    this.dialogRef.close();
  }
  ngOnDestroy() {
    this.currentLanguage$?.unsubscribe();
  }
}
