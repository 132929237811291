<div class="modalmain boxshodo">
    
  <div class="p-15 border-bottom modal-header bg-light">
      <div class="modal-text">
          <h6 class="mb-0"*ngIf="editActionId == ''; else editHeader">{{translations?.create}} {{translations?.announcement}}</h6>
          <ng-template #editHeader>
              <h6 class="br-section-label mb-0">{{translations?.update}} {{translations?.announcement}}</h6>
          </ng-template>
      </div>
      <button class="btn-close btn ms-4 ms-auto"><i class="bi bi-x-lg" (click)="closeDialog()"></i></button>
  </div>

 
      <form [formGroup]="announceForm">
        <div class="card-body h-auto pt-3 d-block" id="announce-form">
          
            <div class="row">
              <div class="col-lg-6 mt-4">
                <div class="form-group position-relative mb-0 flex-grow-1">
                  <label for="">{{translations?.title}} &nbsp;<span class="text-danger">*</span></label>
                  <input type="text" formControlName="title" placeholder="{{translations?.enterTitleName}}" class="form-control">
                  <p class="error-element" *ngIf="announceForm.controls['title'].hasError('required') && (announceForm.controls['title'].dirty || announceForm.controls['title'].touched)">{{translations?.thisFieldIsRequired}}</p>
                </div>   
              </div>
              <div class="col-lg-6 mt-4">       
                <div class="form-group position-relative mb-0 flex-grow-1">
                  <label for="">{{translations?.publishStatus}} &nbsp;<span class="text-danger">*</span></label>
                  <ngx-select-dropdown formControlName="publish_status" [options]="publishStatusMaster" (change)="getPublishStatusMaster($event)" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}" [multiple]="false"></ngx-select-dropdown>
                  
                </div>
                <p class="error-element" *ngIf="announceForm.controls['publish_status'].hasError('required') && (announceForm.controls['publish_status'].dirty || announceForm.controls['publish_status'].touched)">{{translations?.thisFieldIsRequired}}</p>
              </div>

              <div class="col-lg-12 mt-4">
                <!-- <div class="form-group position-relative mb-2 flex-grow-1">
                  <label for="">{{translations?.description}} *</label>
                  <textarea name="" formControlName="description" placeholder="{{translations?.enterDescription}}" class="form-control" style="height:100px; padding: 12px;"></textarea>
                  <p class="error-element" *ngIf="announceForm.controls['description'].hasError('required') && (announceForm.controls['description'].dirty || announceForm.controls['description'].touched)">{{translations?.thisFieldIsRequired}}</p>  
                </div> -->
                <div class="NgxEditor__Wrapper smeditor">
                  <ngx-editor-menu [editor]="editor" [toolbar]="Global.toolbar"> </ngx-editor-menu>
                  <ngx-editor [editor]="editor" formControlName="description" [required]="true" [placeholder]="translations?.enterDescription"></ngx-editor>
              </div>
              </div>

            </div>
          
          
          
        </div>  
        <div class="modal-footer p-15 py-2 border-top">                    
          <!-- <button class="btn button-solide">Add</button> -->
          <button type="submit" [disabled]="!announceForm.valid" (click)="create($event)" class="btn button-solide" *ngIf="editActionId == ''; else editButtonSection">
              <span >{{translations?.add}}</span>
          </button>

          <ng-template #editButtonSection>
              <button type="button" (click)="closeDialog()" class="btn button-solide me-3">
                  <span>{{translations?.cancelEditing}}</span>
              </button>
              <button type="submit" [disabled]="!announceForm.valid" *ngIf="
              Global.checkCompanyModulePermission({company_module: 'announcement',
                company_sub_module: 'announcement',
                company_sub_operation: ['add','edit'],
                company_strict: true
              })" (click)="update($event)" class="btn button-solide">
                  <span>{{translations?.update}}</span>
              </button>
          </ng-template>
        </div>
      </form>    



</div>
