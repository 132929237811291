
<div class="app-content leave-cms">
    <app-menu-sidebar></app-menu-sidebar>

<div class="info-fluid">
    <div class="app-header justify-content-end topfinance-nav">

        <!-- <app-language class="ms-auto"></app-language> -->
        <app-profile></app-profile>
        
    </div>
    <div class="card info_box">
      <div class="p-15 border-bottom announcements_div flex-wrap">
        <div class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0">{{translations?.announce}}</h5>
            <div class="d-flex ms-auto">
                <div class="serch-main me-13">
                    <i class="bi bi-search"></i>
                    <input type="text" placeholder="Search by announcement..." class="form-control" [formControl]="searchkey">
                </div>
                <button class="btn button-solide btn-border" (click)="routeToannounce()">+ {{translations?.addAnnounce}}</button>
            </div>
        </div>
      </div>
      <div class="p-15">
        <div class="announsec ps-4">

            <ng-container *ngFor="let annc of announcementList;let i = index">
                <div class="announbox">
                    <div class="announboxx">
                        <h2 class="cursor" (click)="routeToanncDetails(annc?._id)">{{annc?.title}}</h2>
                        <div class="d-flex align-items-center mb-2">
                            <div class="user_dtls d-flex align-items-center cursor" (click)="routeToanncDetails(annc?._id)">
                                <ng-container *ngIf="annc?.company_logo;  else noImg">
                                    <figure><img [src]="annc?.company_logo" alt="user"></figure>
                                </ng-container>
                                <ng-template #noImg>
                                    <figure><img src="assets/images/user.jpg" alt="user"></figure>
                                </ng-template>
                                <figcaption><h3>{{translations?.by}} <span class="text-primary">{{annc?.company_name}}</span></h3></figcaption>
                            </div>
                            <div class="anndatetine ms-4">
                                <i class="bi bi-calendar-fill"></i>
                                <span>{{annc?.updated_at ? (annc?.updated_at | date:'dd-MM-yyyy') : 'N/A'}} :: {{annc?.updated_at ? (annc?.updated_at | date:'MMM') : 'N/A'}} {{annc?.updated_at ? (annc?.updated_at | date:'shortTime') : 'N/A'}}</span>

                                <!-- <span>{{Global.getSplitDate(item?.updated_at)}}:: {{item?.updated_at | date:'shortTime'}}</span> -->
                            </div>                    
                        </div>
                        <div class="annutxt" [innerHTML]="annc?.description">
                           <!-- <p>{{annc?.description}}</p> -->
                        </div>
                        <div class="annucom">
                            <div class="anndatetine">
                                <i class="bi bi-chat-dots-fill"></i>
                                <span class="text-primary me-3 cursor" (click)="getAnnouncementReply(annc?._id, i)">{{ annc?.replies?.length ? annc?.replies?.length : annc?.reply_length ?? 0}} {{translations?.comments}}</span>
                                <span (click)="focusOnInput(i)"><span class="text-primary cursor">{{translations?.reply}}</span></span>
                            </div>
                        </div>
                    </div>
                   
                    <div [ngClass]="{'hidden': !annc?.showInput}">
                        <ng-container *ngFor="let item of annc?.replies; let i = index">
                        
                       <div class="innerannounbox">
                        <div class="d-flex align-items-center mb-2">
                            <div class="user_dtls d-flex align-items-center">
                                <ng-container *ngIf="item?.company_logo;  else emp_img">
                                    <figure><img [src]="item?.company_logo" alt="user"></figure>
                                </ng-container>
                                <ng-template #emp_img >
                                    <ng-container *ngIf="item?.profile_pic;  else noImg">
                                        <figure><img [src]="item?.profile_pic" alt="user"></figure>
                                    </ng-container>
                                    <ng-template #noImg>
                                        <figure><img src="assets/images/user.jpg" alt="user"></figure>
                                    </ng-template>
                                </ng-template>
                                
                                <figcaption><h3>{{translations?.by}} <span class="text-primary">{{item?.company_name ? item?.company_name : item?.emp_first_name + ' ' + item?.emp_last_name }}</span></h3></figcaption>
                            </div>
                            <div class="anndatetine ms-4">
                                <i class="bi bi-calendar-fill"></i>
                                <span>{{item?.updated_at ? (item?.updated_at | date:'dd-MM-yyyy') : 'N/A'}} :: {{item?.updated_at ? (item?.updated_at | date:'MMM') : 'N/A'}} {{item?.updated_at ? (item?.updated_at | date:'shortTime') : 'N/A'}}</span>
                            </div>                    
                        </div>
                        <div class="annutxt" >
                            <p > {{item?.reply_description}}</p>

                        </div>
                    </div>
                    
                </ng-container>
                <!-- <p *ngIf="annc?.replies?.viewReply > 0;" class="cursor" (click)="getAnnouncementReplypage(annc?._id, i)">{{translations?.viewMore}} {{annc?.replies?.viewReply}} {{translations?.replies}}</p> -->
                    </div>
                    <form [formGroup]="replyForm">
                        <div class="annucommentbox d-flex align-items-center">
                            <!-- <button class="btn"><img src="assets/images/icon18.png" alt="user"></button> -->
                            <!-- <button class="btn"><img src="assets/images/icon19.png" alt="user"></button> -->
                            <input type="text" [id]="'replyInput_' + i" formControlName="reply_description" [placeholder]="translations?.commentHere" autocomplete="off" class="form-control">
                            <button (click)="createReply(annc?._id, i)" class="btn py-1"><img src="assets/images/icon20.png" alt="user"></button>
                        </div>
                    </form>
                </div>
            </ng-container>
          

           <div *ngIf="announcementList?.length  === 0 || announcementList?.length  === undefined">
            <p class="text-center">{{translations?.noAnnouncementAvailable}}</p>
           </div>

        </div>
      </div>
      <app-custom-pagination [rows]="announcementList" [paginationOptions]="paginationOptions" (onPageClicked)="getAnnouncement($event, null )"></app-custom-pagination>

    </div>
</div>
</div>


