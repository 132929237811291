<div class="app-content leave-cms">
    <app-menu-sidebar></app-menu-sidebar>
    <div class="info-fluid">
        <div class="app-header justify-content-end opfinance-nav">

            <!-- <app-language class="ms-auto"></app-language> -->
            <app-profile></app-profile>
            
        </div>
        <div class="card info_box">
      <div class="p-15 border-bottom">
        <div class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0">{{announceSingleData?.title ?? 'N/A'}} </h5>
            <div class="d-flex ms-auto">
                <!-- <div class="serch-main me-13">
                    <i class="bi bi-search"></i>
                    <input type="text" placeholder="Search by name, id..." class="form-control">
                </div> -->
                <button class="btn button-solide btn-lightblue" (click)="routeToAllAnnc()">{{translations?.viewAllAnnouncement}}</button>
            </div>
        </div>
      </div>
      <div class="p-15">
        <div class="announsec ps-4">
            <div class="announbox">
                <div class="announboxx">
                    <!-- <h2>Ivan Infotech has announce 5% bonus to the Employees.</h2> -->
                    <div class="d-flex align-items-center mb-3">
                        <div class="user_dtls d-flex align-items-center">
                            <ng-container *ngIf="announceSingleData?.company_logo;  else noImg">
                                <figure><img [src]="announceSingleData?.company_logo" alt="user"></figure>
                            </ng-container>
                            <ng-template #noImg>
                                <figure><img src="assets/images/user.jpg" alt="user"></figure>
                            </ng-template>
                            <figcaption><h3>{{translations?.by}} <span class="text-primary">{{announceSingleData?.company_name}}</span></h3></figcaption>
                        </div>
                        <div class="anndatetine ms-4">
                            <i class="bi bi-calendar-fill"></i>
                            <span>{{announceSingleData?.updated_at ? (announceSingleData?.updated_at | date:'dd-MM-yyyy') : 'N/A'}} :: {{announceSingleData?.updated_at ? (announceSingleData?.updated_at | date:'MMM') : 'N/A'}} {{announceSingleData?.updated_at ? (announceSingleData?.updated_at | date:'shortTime') : 'N/A'}}</span>
                        </div>                    
                    </div>
                    <div class="annutxt" [innerHTML]="announceSingleData?.description">
                        <!-- <p>{{announceSingleData?.description}}</p> -->
                    </div>
                    <div class="annucom">
                        <div class="anndatetine">
                            <i class="bi bi-chat-dots-fill"></i>
                            <span class="text-primary me-3 cursor" (click)="viewComments()"  >{{ announceReplyData.length ? announceReplyData.length : announceSingleData?.reply_length ?? 0}} {{translations?.comments}}</span>
                            <span (click)="focusOnInput()"><span class="text-primary cursor">{{translations?.reply}}</span></span>
                        </div>
                    </div>
                </div>

                <div [ngClass]="{'hidden': showComments}">
                <ng-container *ngFor="let item of announceReplyData">
                    <div class="innerannounbox">
                        <div class="d-flex align-items-center mb-3">
                            <div class="user_dtls d-flex align-items-center">
                                <ng-container *ngIf="item?.company_logo;  else emp_img">
                                    <figure><img [src]="item?.company_logo" alt="user"></figure>
                                </ng-container>
                                <ng-template #emp_img >
                                    <ng-container *ngIf="item?.profile_pic;  else noImg">
                                        <figure><img [src]="item?.profile_pic" alt="user"></figure>
                                    </ng-container>
                                    <ng-template #noImg>
                                        <figure><img src="assets/images/user.jpg" alt="user"></figure>
                                    </ng-template>
                                </ng-template>
                                <figcaption><h3>{{translations?.by}} <span class="text-primary">{{item?.company_name ? item?.company_name : item?.emp_first_name + ' ' + item?.emp_last_name }}</span></h3></figcaption>
                            </div>
                            <div class="anndatetine ms-4">
                                <i class="bi bi-calendar-fill"></i>
                                <span>{{item?.updated_at ? (item?.updated_at | date:'dd-MM-yyyy') : 'N/A'}} :: {{item?.updated_at ? (item?.updated_at | date:'MMM') : 'N/A'}} {{item?.updated_at ? (item?.updated_at | date:'shortTime') : 'N/A'}}</span>
                            </div>                    
                        </div>
                        <div class="annutxt">
                            <p> {{item?.reply_description}}</p>
                        </div>
                        </div>
                        </ng-container>
                       
                </div>
                <form [formGroup]="replyForm">
                    <div class="annucommentbox d-flex align-items-center">
                        <!-- <button class="btn"><img src="assets/images/icon18.png" alt="user"></button> -->
                        <!-- <button class="btn"><img src="assets/images/icon19.png" alt="user"></button> -->
                        <input type="text" #myInput [placeholder]="translations?.commentHere" formControlName="reply_description" class="form-control">
                        <button class="btn" (click)="createReply()"><img src="assets/images/icon20.png" alt="user"></button>
                    </div>
                </form>
            </div>
            <div *ngIf="!announceSingleData">
                <p class="text-center">{{translations?.noAnnouncementAvailable}}</p>
            </div>
        </div>
      </div>
    </div>
 </div>
</div>