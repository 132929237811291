<form [formGroup]="filterForm">

  <div class="row g-3">

    <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="searchKeyFilter == true">
      <label>{{translations?.searchlabel}}</label>
      <input type="text" formControlName="searchkey" class="form-control static-text"
        [placeholder]="translations?.search" />
    </div>

    <div *ngIf="empNameFilter" class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative">
      <label>{{translations?.searchName}}</label>
      <input
        type="text"
        class="form-control"
        [placeholder]="translations?.searchName"
        formControlName="emp_name"
      />
    </div>
    <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="monthFilter == true">
      <label class="static-text">{{translations?.month}}</label>
      <ngx-select-dropdown formControlName="month" [options]="monthMaster" class="static-text"
        [config]="{ search: true, placeholder: translations?.month }"></ngx-select-dropdown>
    </div>
    <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="yearFilter == true">
      <label class="static-text">{{translations?.year}}</label>
      <ngx-select-dropdown class="static-text" formControlName="year" [options]="yearMaster"
        [config]="{ search: true, placeholder:translations?.year }"></ngx-select-dropdown>
    </div>


    <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="calendarFrom == true">
      <label class="static-text">{{translations?.from}}</label>
      <input type="date"  formControlName="date_start_from" class="form-control w-100">
      <!-- <ngx-select-dropdown formControlName="month" [options]="monthMaster" class="static-text"
        [config]="{ search: true, placeholder: translations?.month }"></ngx-select-dropdown> -->
    </div>

    <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="calendarTo == true">
      <label class="static-text">{{translations?.to}}</label>
      <input type="date"  formControlName="date_end_to" class="form-control w-100">
      <!-- <ngx-select-dropdown formControlName="month" [options]="monthMaster" class="static-text"
        [config]="{ search: true, placeholder: translations?.month }"></ngx-select-dropdown> -->
    </div>

    <!-- <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="monthFilter == true">
      <label class="static-text">{{translations?.from}}</label>
    </div>
     -->
    

    <div *ngIf="isBankAccount" class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative">
      <label class="static-text">{{translations?.bankAccount}}</label>
      <ngx-select-dropdown formControlName="bank_id" [options]="bankAccounts" class="static-text"
        [config]="{
                  search: true,
                  placeholder: translations?.bankAccount
                }"></ngx-select-dropdown>
    </div>
    <!-- <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="searchKeyFilter == true">
              <label>Search</label>
              <input
                type="text"
                formControlName="searchkey"
                class="form-control static-text"
                placeholder="Search..."/>
            </div> -->

    <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="attendanceTypeFilter == true">
      <label class="static-text">{{translations?.attendance}}</label>
      <ngx-select-dropdown formControlName="attendance_type" class="static-text" [options]="attendanceTypeMaster"
        [config]="{ search: true, placeholder: translations?.attendanceType }" [multiple]="false">
      </ngx-select-dropdown>
    </div>
    <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="dateFromFilter == true">
      <label>Date From</label>
      <input formControlName="date_from" class="form-control p-1 px-2" type="month" placeholder="MM/YYYY"
        [max]="curruntYear + '-0' + curruntMonth" />
    </div>
    <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="dateToFilter == true">
      <label>Date To</label>
      <input formControlName="date_to" class="form-control p-1 px-2" type="month" placeholder="MM/YYYY"
        [min]="filterForm.get('date_from')?.value || curruntYear + '-0' + curruntMonth"
        [max]="getDateFromToMaxValidation(filterForm.get('date_from')?.value) || curruntYear + '-0' + curruntMonth" />
    </div>
    <ng-content select="[extraFilter]"></ng-content>

    <ng-container *ngIf="basicFilter == true">
      <div *ngIf="isClient" class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative">
        <label >{{translations?.client}}</label>
        <ngx-select-dropdown formControlName="client_id" [options]="clientMaster" class="static-text"
          [config]="{ search: true, placeholder: translations?.selectClient }" [multiple]="true"></ngx-select-dropdown>
      </div>
      <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative">
        <label >{{translations?.branch}}</label>
        <ngx-select-dropdown formControlName="branch_id" [options]="branchMaster" class="static-text"
          [config]="{ search: true, placeholder: translations?.selectBranch}"
          [multiple]="isBranchMultiple"></ngx-select-dropdown>
      </div>
      <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative">
        <label >{{translations?.department}}</label>
        <ngx-select-dropdown formControlName="department_id" class="static-text" [options]="departmentMaster" [config]="{
                  search: true,
                  placeholder: translations?.selectDepartment
                }" [multiple]="true"></ngx-select-dropdown>
      </div>
      <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative">
        <label >{{translations?.designation}}</label>
        <ngx-select-dropdown formControlName="designation_id" class="static-text" [options]="designationMaster"
          [config]="{
                  search: true,
                  placeholder: translations?.selectDesignation
                }" [multiple]="true"></ngx-select-dropdown>
      </div>
      <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="isHod">
        <label >{{translations?.hod}}</label>
        <ngx-select-dropdown formControlName="hod_id" [options]="hodMaster" class="static-text"
          [config]="{ search: true, placeholder: translations?.selectHod }" [multiple]="true"></ngx-select-dropdown>
      </div>


      <div *ngIf="salarySheet" class="form-group col-md-4 col-lg-3 col-xl-2 mb-0">
        <ngx-select-dropdown formControlName="salary_sheet" [options]="salarySheetMaster"
          class="form-control p-0 border-0 static-text" [config]="{
                  search: true,

                  displayKey: 'description',
                  placeholder: translations?.salarySheet
                }" [multiple]="false"></ngx-select-dropdown>
      </div>

      <div *ngIf="challanType" class="form-group col-md-4 col-lg-3 col-xl-2 mb-0">
        <ngx-select-dropdown formControlName="challan_type" [options]="challanTypeMaster"
          class="form-control p-0 border-0 static-text" [config]="{
                  search: true,
                  displayKey: 'description',
                  placeholder: translations?.chalanType
                }" [multiple]="true"></ngx-select-dropdown>
      </div>

      <div *ngIf="registerType" class="form-group col-md-4 col-lg-3 col-xl-2 mb-0">
        <ngx-select-dropdown formControlName="register_type" [options]="challanTypeMaster"
          class="form-control p-0 border-0 static-text" [config]="{
                  search: true,
                  displayKey: 'description',
                  placeholder: translations?.registerType
                }" [multiple]="true"></ngx-select-dropdown>
      </div>

      <div *ngIf="salaryTypeFilter" class="form-group col-md-4 col-lg-3 col-xl-2 mb-0">
        <ngx-select-dropdown formControlName="salary_type" [options]="['Salary', 'Supplement', 'Earning']"
          class="form-control p-0 border-0 static-text" [config]="{
                  search: true,
                  placeholder: translations?.salaryType
                }"></ngx-select-dropdown>
      </div>

      <div *ngIf="bankInstStatus" class="form-group col-md-4 col-lg-3 col-xl-2 mb-0">
        <ngx-select-dropdown formControlName="bankinst_status" [options]="bankInstStatusMaster"
          class="form-control p-0 border-0 static-text" [config]="{
                  search: true,
                  placeholder: translations?.salaryPaymentStatus
                }"></ngx-select-dropdown>
      </div>

      <div *ngIf="bankInstPayType" class="form-group col-md-4 col-lg-3 col-xl-2 mb-0">
        <ngx-select-dropdown formControlName="bankinst_pay_type" [options]="bankInstPayTypeMaster"
          class="form-control p-0 border-0 static-text" [config]="{
                  search: true,
                  placeholder: translations?.salaryChallanType
                }"></ngx-select-dropdown>
      </div>


      <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="empDetailsFilter == true">

        <ngx-select-dropdown formControlName="emp_status" [options]="empStatusMaster" class="form-control p-0 border-0"
          [config]="{ search: true, placeholder: translations?.selectStatus }" [multiple]="false"></ngx-select-dropdown>
      </div>

    </ng-container>

    <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="reportType">
      <label class="static-text">{{translations?.reportType}}</label>
      <ngx-select-dropdown (change)="chnagereportType($event)" #reportType formControlName="report_type"
        [options]="reportTypeMaster" class="form-control p-0 border-0 static-text" [config]="{
                  search: true,
                  displayKey: 'description',
                  placeholder: translations?.reportType
                }"></ngx-select-dropdown>
    </div>
    <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0" *ngIf="advanceFilter == true">
      <label for="advance-filter" class="wbtn checkbox-div">
        <input type="checkbox" id="advance-filter" class="static-text" formControlName="advance_filter" />&nbsp;
        {{translations?.advanceFilter}}
      </label>
    </div>

    <div class="form-group col-md-8 col-lg-6 col-xl-4 col-xxl-2 d-flex align-items-center mb-0" *ngIf="rivisionreport_type==true">
      <label for="Effective_Dt" class="wbtn checkbox-div">
      <input type="radio" formControlName="search_type" value="effective_date" class="static-text" id="Effective_Dt"/>&nbsp; Effective Dt</label> 
    &nbsp;&nbsp;

      <label for="Revision_Dt" class="wbtn checkbox-div">
        <input
          type="radio"
          value="revision_date"
          id="Revision_Dt"
          class="static-text"
          formControlName="search_type"
        />&nbsp; Revision Dt
      </label>
    </div>
    <div *ngIf="frequencyFilter" class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative">
      <label class="static-text">Disbursement Frequency</label>
      <ngx-select-dropdown
        formControlName="disbursement_frequency"
        [options]="frequencyMaster"
        class="form-control p-1"
        [config]="{
          search: true,
          displayKey: 'description',
          placeholder: 'Select Bonus Type'
        }"
        [multiple]="false"
      ></ngx-select-dropdown>
    </div>
    <div *ngIf="disburesmentFilter" class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative">
      <label class="static-text">Disbursement type</label>
      <ngx-select-dropdown
        formControlName="disbursement_type"
        [options]="disburesmentMaster"
        class="form-control p-1"
        [config]="{
          search: true,
          displayKey: 'description',
          placeholder: 'Select Disbursement'
        }"
        [multiple]="false"
      ></ngx-select-dropdown>
    </div>
    <!-- <div class="form-group col-md-4 col-lg-3 col-xl-2 mb-0 position-relative" *ngIf="attendanceTypeFilter == true">
                <label>Attendance</label>
                <ngx-select-dropdown
                    formControlName="attendance_type"
                    [options]="attendanceTypeMaster"
                    [config]="{ search: true, placeholder: 'Attendance Type' }"
                    [multiple]="true">
                </ngx-select-dropdown>
            </div> -->
    <ng-container *ngIf="filterForm?.get('advance_filter')?.value == true">

      <div class="col-sm-12 col-12">
        <div class="addfilter-main borderstyle">

          <div class="g-3 row">

            <div class="col-12 col-sm-12 col-lg-4 mb-3">
              <div class="form-group">
                <label>Your Age</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text static-text">{{translations?.age}}</span>
                  </div>
                  <input type="text" class="form-control static-text" formControlName="age_from"
                    placeholder={{translations?.from}} style="width: 25px" />
                  <input type="text" class="form-control static-text" formControlName="age_to"
                    placeholder={{translations?.to}} style="width: 25px" />
                </div>
              </div>
            </div>

            <div class="col-md-4 col-lg-4 position-relative mb-3">
              <div class="form-group">
                  <label>Select Gender</label>
                  <ngx-select-dropdown formControlName="gender" [options]="genderMaster" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'select'}">
                  </ngx-select-dropdown>
                </div>
            </div>

            <div class="form-group col-md-4 col-lg-4 mb-0 position-relative">
              <div class="form-group">
                  <label>Select Religion</label>
                  <ngx-select-dropdown formControlName="religion" [options]="religionMaster" class="form-control p-0 border-0" [config]="{search: true, placeholder: translations?.selectSeligion }"></ngx-select-dropdown>
              </div>
            </div>

                  <div class="col-12 col-sm-6 col-lg-4">

                        <div class="mx-auto">
                            <p class="error-element"
                              *ngIf="(filterForm.controls['age_from'].hasError('min') && filterForm.controls['age_from'].dirty) || (filterForm.controls['age_to'].hasError('min') && filterForm.controls['age_to'].dirty)">
                              {{translations?.enterValidAge}}</p>
                            <p class="error-element"
                              *ngIf="(filterForm.controls['age_from'].hasError('max') && filterForm.controls['age_from'].dirty) || (filterForm.controls['age_to'].hasError('max') && filterForm.controls['age_to'].dirty)">
                              {{translations?.enterValidAge}}</p>
                        </div>
                          <!-- <div class="col-6">
                              <p class="error-element" *ngIf="filterForm.controls.age_to.hasError('pattern')">Enter a valid age</p>
                          </div> -->
                  </div>
          </div>

          <div class="g-3 row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{translations?.dateOfJoining}}</label>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text static-text">{{translations?.from}}</span>
                      </div>
                      <input type="date" class="form-control static-text" formControlName="doj_from"
                        placeholder={{translations?.from}} />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text static-text">{{translations?.to}}</span>
                      </div>
                      <input type="date" class="form-control static-text" formControlName="doj_to"
                        placeholder={{translations?.to}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{translations?.dateOfExit}}</label>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text static-text">{{translations?.from}}</span>
                      </div>
                      <input type="date" class="form-control static-text" formControlName="doe_from"
                        placeholder={{translations?.from}} />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text static-text">{{translations?.to}}</span>
                      </div>
                      <input type="date" class="form-control static-text" formControlName="doe_to"
                        placeholder={{translations?.to}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

           

          </div>

        </div>
      </div>

    </ng-container>

   

    <div *ngIf="isFilterBtnHidden" class="form-group col-md-4 col-xxl-1 col-xl-2 col-lg-3 mb-0 position-relative">

      <button (click)="filter()" type="submit" class="btn  button-solide static-text w-100"
        [disabled]="!filterForm.valid || isFilterBtnDisable">
        {{translations?.apply}}
      </button>
    </div>

    <div class="form-group col-md-4 col-xxl-1 col-xl-2 col-lg-3 mb-0 position-relative">

      <button (click)="clearForm()" [disabled]="!isFormModified" class="btn static-text button-solide gray-btn w-100">
        {{translations?.clearFilter}}
      </button>
    </div>

   
    
    <ng-content select="[addOn_btn1]"></ng-content>
    <ng-content select="[addOn_btn2]"></ng-content>
    <ng-content select="[addOn_btn3]"></ng-content>
    <ng-content select="[otherData]"></ng-content>

    <!-- <button class="btn button-solide me-13">Apply</button>
            <button class="btn button-solide gray-btn">Cancel</button> -->

  </div>
</form>