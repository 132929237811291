import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import { NoticeService } from 'src/app/services/notice.service';
import * as Global from 'src/app/shared/globals';
import { ViewImageComponent } from '../../profile/employee-listing/modal/view-image/view-image.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer,  SafeResourceUrl } from '@angular/platform-browser';
import { ViewDocComponent } from '../modal/view-doc/view-doc.component';
import PaginationOptions from 'src/app/models/PaginationOptions';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-notice-list',
  templateUrl: './notice-list.component.html',
  styleUrls: ['./notice-list.component.css']
})
export class NoticeListComponent implements OnInit{
lang:any = 'en'

  safePdfUrl?: SafeResourceUrl;
  paginationOptionsExpired: PaginationOptions = Global.resetPaginationOption();
  paginationOptionsExpiring: PaginationOptions = Global.resetPaginationOption();

  Global = Global;
  noticeExpiringList:any[] = []
  noticeExpiredList:any[] = []
  currentLanguage$?:Subscription
  translations:any;
  
  constructor(
    private noticeService:NoticeService,
    private langService:LanguageTranslateService,
    private route:ActivatedRoute,
    private dialogRef:MatDialog,
    private router: Router,
    private sanitizer: DomSanitizer,
    private spinner:NgxSpinnerService

  ){
    this.getNoticeExpiring();
    this.getNoticeExpired();
    this.route.paramMap.subscribe((params: any) => {
      this.lang = params.get('lang');

    });
  }

  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
    this.lang = this.langService.getLangLocalStorage();
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('home', 'AnnouncementListComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
  }

  singleViewImage(fileName: any) {

    this.noticeService.singleViewImage({ 'image_path': fileName }).then((res: any) => {

        if (res.status == 'success') {
          this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res?.image);

          // console.log(this.safePdfUrl,'safedurl');
          
            const dialogRef = this.dialogRef.open(ViewImageComponent, {
                minWidth: '500px',
                height: '500px',
                panelClass: 'full-screen-modal',
                hasBackdrop:true,
                data:this.safePdfUrl
              });
          
              dialogRef.afterClosed().subscribe(result => {
              });
        }
    })
}

  viewDoc(img:any){
    this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(img);
    // console.log('view documenjt',this.safePdfUrl);
    this.noticeService.singleViewImage({ 'image_path': img }).then((res: any) => {

      if (res.status == 'success') {
        this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res?.image);

        // console.log(this.safePdfUrl,'safedurl');
        
          const dialogRef = this.dialogRef.open(ViewDocComponent, {
              minWidth: '500px',
              height: '500px',
              panelClass: 'full-screen-modal',
              hasBackdrop:true,
              data:this.safePdfUrl
            });
        
            dialogRef.afterClosed().subscribe(result => {
            });
      }
  })
    // const dialogRef = this.dialogRef.open(ViewDocComponent, {
    //   maxWidth: '701px',
    //   width: '100%',
    //   height: '500px',
    //   panelClass: 'full-screen-modal',
    //   hasBackdrop:true,
    //   data: this.safePdfUrl
    // });

    // dialogRef.afterClosed().subscribe(result => {
    // });
  }

  getNoticeExpiring({page = <any>null,
    loading = <boolean>true,
    filter = <any>null,
  } = {}){
    const date = new Date();
    return new Promise((resolve,reject)=>{
      this.noticeService.getNotice(
        { 
          "pageno":this.paginationOptionsExpiring.page,
          "perpage":10,
          "type":"expiring"
        }).then((res:any)=>{
          if (res?.status === 'success') {
            const data:any = res?.data ?? [];
            this.noticeExpiringList = res?.data?.docs ?? [];  
            this.paginationOptionsExpiring = {
              page: data?.page,
              limit: data?.limit,
              hasNextPage: data?.hasNextPage,
              hasPrevPage: data?.hasPrevPage,
              nextPage: data?.nextPage,
              prevPage: data?.prevPage,
              totalDocs: data?.totalDocs,
              totalPages: data?.totalPages,
              pagingCounter: data?.pagingCounter
            }     
          }
            
          resolve(true);
          
      }).catch((err:any)=>{
        reject(err)
      })
    })
  }
  getNoticeExpired({page = <any>null,
    loading = <boolean>true,
    filter = <any>null,
  } = {}){
    const date = new Date();
    return new Promise((resolve,reject)=>{
      
      this.spinner.show();
      this.noticeService.getNotice(
        { 
          "pageno":this.paginationOptionsExpired.page,
          "perpage":10,
          "type":"expired"
        }).then((res:any)=>{
          if (res?.status === 'success') {
            const data:any = res?.data ?? [];
          this.noticeExpiredList = res?.data?.docs ?? [];
          this.paginationOptionsExpired = {
            page: data?.page,
            limit: data?.limit,
            hasNextPage: data?.hasNextPage,
            hasPrevPage: data?.hasPrevPage,
            nextPage: data?.nextPage,
            prevPage: data?.prevPage,
            totalDocs: data?.totalDocs,
            totalPages: data?.totalPages,
            pagingCounter: data?.pagingCounter
          }
          }
          this.spinner.hide();
          resolve(true);
      }).catch((err:any)=>{
        this.spinner.hide();
        reject(err)
      })
    })
  }
  routeTonoticeDetails(id:any){
    this.router.navigate([this.lang,'employees',id,'edit','documents'])
  }
}
