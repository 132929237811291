import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private companyDetails = new BehaviorSubject<any>({});

    constructor(
        private webService: WebService,
        private spinner: NgxSpinnerService
    ) { }
    setCompanyDetails(data: any) {
        this.companyDetails.next(data);
    }
    getCompanyDetails() {
        return this.companyDetails.getValue();
    }
    clearCompanyDetails() {
      this.companyDetails.next({}); 
    }
    getCompanyData(payload: any) {
        return this.webService.postFormData('company/get-company-data', payload);
    }
    downloadBufferFile(url: string, payload?: Object) {
        return this.webService.post('company/' + url, payload);
    }

    singleViewImage(payload: any) {
        return this.webService.post('company/single-view-image', payload);
      }
      
    updateCompanyPartners(payload: any, type: string) {
        let apiEndpoint: string = '';
        switch (type) {
            case 'add_partner': case 'edit_partner':
                apiEndpoint = 'company/update-company-partner';
                break;
            case 'edit_est_details':
                apiEndpoint = 'company/update-company-establishment';
                break;
            case 'branch_details': case 'new_branch_details':
                apiEndpoint = 'company/update-company-branch';
                break;
            case 'edit_reg_ofc_address':
                apiEndpoint = 'company/update-company-reg-ofc-address';
                break;
            case 'edit_com_ofc_address':
                apiEndpoint = 'company/update-company-comm-ofc-address';
                break;
            case 'edit_pref_settings':
                apiEndpoint = 'company/update-company-preference-settings';
                break;
            case 'edit_company_profile':
                apiEndpoint = 'company/update-company-details';
                break;
            default:
                break;
        }
        return this.webService.postFormData(apiEndpoint, payload);
    }

    // masters settings
    getDepartmentsMaster(payload: any) {
        return this.webService.post('company/department-list', payload);
    }
    updateDepartment(payload: any, type: string) {
        let apiEndpoint: string = '';
        switch (type) {
            case 'edit_department':
                apiEndpoint = payload?.department_id ? 'company/update_department' : 'company/create_department';
                break;
            case 'edit_designation':
                apiEndpoint = payload?.designation_id ? 'company/update_designation' : 'company/create_designation';
                break;
            case 'edit_client':
                apiEndpoint = payload?.client_id ? 'company/update_client_data' : 'company/add_client';
                break;
            case 'add_document':
                apiEndpoint = payload?.document_master_id ? 'company/edit-document-master' : 'company/add-document-master';
                break;
            default:
                break;
        }
        return this.webService.post(apiEndpoint, payload);
    }
    departmentStatus(payload: any) {
        return this.webService.post('company/update_department_status', payload);
    }
    departmentDelete(payload: any) {
        return this.webService.post('company/delete_department', payload);
    }
    getDesignationMaster(payload: any) {
        return this.webService.post('company/designation-list', payload);
    }
    designationStatus(payload: any) {
        return this.webService.post('company/update_designation_status', payload);
    }
    designationDelete(payload: any) {
        return this.webService.post('company/delete_designation', payload);
    }
    getClientMaster(payload: any) {
        return this.webService.post('company/get_clients', payload);
    }
    clientDelete(payload: any) {
        return this.webService.post('company/delete_client', payload);
    }
    getLetterMaster(payload: any) {
        return this.webService.post('company/get-letter-template', payload);
    }
    createLetterMaster(payload: any) {
        return this.webService.post(payload?.template_id ? 'company/update-letter-template' : 'company/create-letter-template', payload);
    }
    letterDelete(payload: any) {
        return this.webService.post('company/delete-letter-template', payload);
    }
    letterStatus(payload: any) {
        return this.webService.post('company/update-letter-template-status', payload);
    }
    getEmailMaster(payload: any) {
        return this.webService.post('company/get-email-template', payload);
    }
    createEmailMaster(payload: any) {
        return this.webService.post(payload?.template_id ? 'company/update-email-template' : 'company/create-email-template', payload);
    }
    emailDelete(payload: any) {
        return this.webService.post('company/delete-email-template', payload);
    }
    emailStatus(payload: any) {
        return this.webService.post('company/update-email-template-status', payload);
    }
    getSmtpMaster(payload: any) {
        return this.webService.post('company/get-smtp-list', payload);
    }
    createSmtpMaster(payload: any) {
        return this.webService.post(payload?.smtp_id ? 'company/update-smtp-access' : 'company/creat-smtp-access', payload);
    }
    smtpDelete(payload: any) {
        return this.webService.post('company/delete-smtp-access', payload);
    }
    smtpStatus(payload: any) {
        return this.webService.post('company/update-smtp-status', payload);
    }
    getLocationMaster(payload: any) {
        return this.webService.post('company/list-company-location', payload);
    }
    createLocationMaster(payload: any) {
        return this.webService.post(payload?.location_id ? 'company/create-company-location' : 'company/create-company-location', payload);
    }
    locationDelete(payload: any) {
        return this.webService.post('company/company-delete', payload);
    }
    locationStatus(payload: any) {
        return this.webService.post('company/company-status-change', payload);
    }
    getDocumentMaster(payload: any) {
        return this.webService.post('company/get-document-master', payload);
    }
    deleteDocumentMaster(payload: any) {
        return this.webService.post('company/delete-document-master', payload);
    }
    getBankPaymentMaster(payload: any) {
        return this.webService.post('company/get-bank-sheet-list', payload);
    }
    deleteBankPaymentMaster(payload: any) {
        return this.webService.post('company/delete-bank-sheet', payload);
    }
    createBankPaymentMaster(payload: any) {
        return this.webService.postFormData(payload?.template_id ? 'company/update-bank-sheet' : 'company/add-bank-sheet', payload);
    }

    // government rules

    fetchEpfoRules(payload: any) {
        return this.webService.post('company/get_epfo_rule', payload);
      }

    createEpfoRule(payload: any) {
        return this.webService.post('company/create_epfo', payload);
      }

    updateEpfoRule(payload: any) {
        return this.webService.post('company/update_epfo_rule', payload);
      }
    
    getActiveEpfoRule(payload: any) {
        return this.webService.post('company/get_active_epfo_rule', payload);
    } 

    deleteEpfoRule(payload: any) {
        return this.webService.post('company/delete_epfo_rule', payload);
      } 

    fetchEsicRules(payload: any) {
        return this.webService.post('company/get_esic_rule', payload);
      }
      
    createEsicRule(payload: any) {
        return this.webService.post('company/create_esic', payload);
      }

      
    updateEsicRule(payload: any) {
        return this.webService.post('company/update_esic_rule', payload);
        } 

    getActiveEsicRule(payload: any) {
            return this.webService.post('company/get_active_esic_rule', payload);
    } 

    deleteEsicRule(payload: any) {
        return this.webService.post('company/delete_esic_rule', payload);
      }

    getBonusRule(payload: any) {
        return this.webService.post('company/get_bonus_rule', payload);
    }
    createBonusRule(payload: any) {
        return this.webService.post(payload?.bonus_id ? 'company/update_bonus_rule' : 'company/create_bonus', payload);
    }
    deleteBonusRule(payload: any) {
        return this.webService.post('company/delete_bonus_rule', payload);
    }
    getActiveBonusRule(payload: any) {
        return this.webService.post('company/get_active_bonus_rule', payload);
    }
    fetchGratuityRules(payload: any) {
        return this.webService.post('company/get_gratuity_rule', payload);
      }
    getActiveGratuityRule(payload: any) {
        return this.webService.post('company/get_active_gratuity_rule', payload);
    }
    deleteGratuityRule(payload: any) {
        return this.webService.post('company/delete_gratuity_rule', payload);
      }

    updateGratuityRule(payload: any) {
        return this.webService.post('company/update_gratuity_rule', payload);
    }
    
    createGratuityRule(payload: any) {
        return this.webService.post('company/create_gratuity', payload);
      }

    
    fetchITaxCategories(payload:any ) {
    return this.webService.post('company/get_tax_categories',payload);
  }

  fetchITaxTemplates(payload: any) {
    return this.webService.post('company/get_taxslabs', payload);
  }

  createITaxTemplate(payload: any) {
    return this.webService.post('company/create_taxslab', payload);
  }

  validateITaxTemplateFinancialYear(payload: any) {
    return this.webService.post('company/validate_financial_year', payload);
  }

  updateITaxTemplate(payload: any) {
    return this.webService.post('company/update_taxslab_rule', payload);
  }

  getActiveITaxSlabRule(payload: any) {
    return this.webService.post('company/get_taxslabs_library', payload);
}

  deleteITaxTemplate(payload: any) {
    return this.webService.post('company/delete_taxslab_data', payload);
  }

  createITaxCategory(payload: any) {
    return this.webService.post('company/create_tax_category', payload);
  }
  updateITaxCategory(payload: any) {
    return this.webService.post('company/update_tax_category', payload);
  }

  getActiveITCategoryRule(payload: any) {
    return this.webService.post('company/get_tax_category_library', payload);
}

   deleteITaxCategory(payload: any) {
    return this.webService.post('company/delete_tax_category', payload);
  }

    // company rules
    getEmployeePackageMaster(payload: any) {
        return this.webService.post('company/employee-package-master', payload);
    }
    getEmployeePackage(payload: any) {
        return this.webService.post('company/employee-package', payload);
    }
    createEmployeePackage(payload: any) {
        return this.webService.post(payload?.package_id ? 'company/update-employee-package' : 'company/add-employee-package', payload);
    }
    updateEmployeePackage(payload: any) {
        return this.webService.post('company/update-employee-package', payload);
      }
      
    getSalaryTemplates(payload: any) {
        return this.webService.post('company/get-salary-template', payload);
    }
    updateSalaryTempStatus(payload: any) {
        return this.webService.post('company/update_salary_template_active_status', payload);
    }
    getSalaryTemplateHead(payload: any) {
        return this.webService.post('company/get-salary-template-head', payload);
    }
    getSalaryTemplateHeadList(payload: any) {
        return this.webService.post('company/get-salary-template-head-list', payload);
    }
    deleteSalaryHead(payload: any) {
        return this.webService.post('company/delete-salary-template-head', payload);
    }
    getSalaryTemplateHeadLibrary(payload: any) {
        return this.webService.post('company/get-salary-template-head-library', payload);
    }
    getSalaryTemplateLibrary(payload: any) {
        return this.webService.post('company/get-salary-template-library', payload);
    }
    createSalaryTemplate(payload: any) {
        return this.webService.post(payload?.template_id ? 'company/update-salary-template' : 'company/add-salary-template', payload);
    }
    addSalaryTemplateHead(payload: any) {
        return this.webService.post('company/add-salary-template-head', payload);
    }

    getAttendanceRules(payload: any) {
        return this.webService.post('company/get_attendance_rule', payload);
    }
    getAttendanceRulesLibrary(payload: any) {
        return this.webService.post('company/get_attendance_library', payload);
    }
    attendanceRuleStatus(payload: any) {
        return this.webService.post('company/update_attendance_rule_active_status', payload);
    }
    attendanceRuleDelete(payload: any) {
        return this.webService.post('company/delete_attendance_rule', payload);
    }
    createAttendanceRule(payload: any) {
        return this.webService.postFormData(payload?.attedance_id ? 'company/update_attendance_rule' : 'company/create_attendance_rule', payload);
    }
    getLeaveRules(payload: any) {
        return this.webService.post('company/get-leave-rule', payload);
    }
    leaveRuleDelete(payload: any) {
        return this.webService.post('company/delete-leave-rule', payload);
    }
    getLeaveTemplateLibrary(payload: any) {
        return this.webService.post('company/get-leave-template-library', payload);
    }
    getLeaveRulesHead(payload: any) {
        return this.webService.post('company/get-template-head', payload);
    }
    createLeaveRule(payload: any) {
        return this.webService.post(payload?.template_id ? 'company/update-leave-rule' : 'company/add-leave-rule', payload);
    }
    getIncentiveRules(payload: any) {
        return this.webService.post('company/get_incentive_policy', payload);
    }
    getIncentiveTemplateLibrary(payload: any) {
        return this.webService.post('company/get_incentive_library', payload);
    }
    incentiveRuleStatus(payload: any) {
        return this.webService.post('company/update_incentive_policy_active_status', payload);
    }
    incentiveRuleDelete(payload: any) {
        return this.webService.post('company/delete_incentive_policy', payload);
    }
    createIncentiveRule(payload: any) {
        return this.webService.post(payload?.incentive_policy_id ? 'company/update_incentive_policy' : 'company/create_incentive_policy', payload);
    }
    getBonusRules(payload: any) {
        return this.webService.post('company/get_bonus_policy', payload);
    }
    getBonusTemplateLibrary(payload: any) {
        return this.webService.post('company/get_bonus_rule_library', payload);
    }
    bonusRuleDelete(payload: any) {
        return this.webService.post('company/delete_bonus_policy', payload);
    }
    bonusRuleStatus(payload: any) {
        return this.webService.post('company/update_bonus_policy_active_status', payload);
    }
    createBonusTemplateRule(payload: any) {
        return this.webService.post(payload?.bonus_policy_id ? 'company/update_bonus_policy' : 'company/create_bonus_policy', payload);
    }
    getOvertimeRules(payload: any) {
        return this.webService.post('company/get_overtime_policy', payload);
    }
    getOvertimeTemplateLibrary(payload: any) {
        return this.webService.post('company/get_overtime_library', payload);
    }
    overtimeRuleDelete(payload: any) {
        return this.webService.post('company/delete_overtime_policy', payload);
    }
    overtimeRuleStatus(payload: any) {
        return this.webService.post('company/update_overtime_policy_active_status', payload);
    }
    createOvertimeTemplateRule(payload: any) {
        return this.webService.post(payload?.overtime_policy_id ? 'company/update_overtime_policy' : 'company/create_overtime_policy', payload);
    }
    getBonusSlipRules(payload: any) {
        return this.webService.post('company/get_bonus_slip_temp', payload);
    }
    getBonusSlipTemplateLibrary(payload: any) {
        return this.webService.post('company/get_bonus_slip_library', payload);
    }
    bonusSlipRuleDelete(payload: any) {
        return this.webService.post('company/delete_bonus_slip_temp', payload);
    }
    createBonusSlipRule(payload: any) {
        return this.webService.postFormData(payload?.bonus_slip_temp_id ? 'company/update_bonus_slip_temp' : 'company/create_bonus_slip_temp', payload);
    }
    getArrearSlipRules(payload: any) {
        return this.webService.post('company/get_arrears_slip_temp', payload);
    }
    getArrearSlipTemplateLibrary(payload: any) {
        return this.webService.post('company/get_arrear_slip_library', payload);
    }
    arrearSlipRuleDelete(payload: any) {
        return this.webService.post('company/delete_arrears_slip_temp', payload);
    }
    createArrearSlipRule(payload: any) {
        return this.webService.postFormData(payload?.arrear_slip_temp_id ? 'company/update_arrears_slip_temp' : 'company/create_arrears_slip_temp', payload);
    }
    getPayslipRules(payload: any) {
        return this.webService.post('company/get_payslip_temp', payload);
    }
    getPayslipTemplateLibrary(payload: any) {
        return this.webService.post('company/get_payslip_temp_library', payload);
    }
    payslipRuleDelete(payload: any) {
        return this.webService.post('company/delete_payslip_temp', payload);
    }
    createPayslipRule(payload: any) {
        return this.webService.postFormData(payload?.payslip_temp_id ? 'company/update_payslip_temp' : 'company/create_payslip_temp', payload);
    }

    fetchTDSTemplates(payload: any) {
        return this.webService.post('company/get_tds_policy', payload);
      }

    deleteTDSTemplate(payload: any) {
        return this.webService.post('company/delete_tds_policy', payload);
      }

    createTDSTemplate(payload: any) {
        return this.webService.post('company/create_tds_policy', payload);
      }
    updateTDSTemplate(payload: any) {
        return this.webService.post('company/update_tds_policy', payload);
      }

    getTdsTemplateLibrary(payload: any) {
        return this.webService.post('company/get_tds_policy_library', payload);
      }  

    updateTDSTemplateStatus(payload: any) {
        return this.webService.post(
          'company/update_tds_policy_active_status',
          payload
        );
      }

    fetchStates() {
        return this.webService.post('company/get_state_list', {
          countrycode: 'IN',
        });
      }  

    fetchPTaxRules(payload: any) {
        return this.webService.post('company/get_ptax_rule', payload);
      }

    updatePTaxRuleStatus(payload: any) {
        return this.webService.postFormData(
          'company/update_ptax_rule_active_status',
          payload
        );
      }

    createPTaxRule(payload: any) {
        return this.webService.postFormData('company/create_ptax_rule', payload);
      }

    updatePTaxRule(payload: any) {
        return this.webService.postFormData('company/update_ptax_rule', payload);
      } 

    getPtaxTemplateLibrary(payload: any) {
        return this.webService.postFormData('company/get_ptax_rule_library', payload);
      } 

    deletePTaxRule(payload: any) {
        return this.webService.post('company/delete_ptax_rule', payload);
      }

    fetchLwfRules(payload: any) {
        return this.webService.post('company/get-lwf-rule', payload);
      }

    createLwfRule(payload: any) {
        return this.webService.postFormData('company/add-lwf-rule', payload);
      }  

    getLwfTemplateLibrary(payload: any) {
        return this.webService.postFormData('company/get-lwf-rule-library', payload);
      } 
    
    updateLwfRule(payload: any) {
        return this.webService.postFormData('company/update-lwf-rule', payload);
      }

    deleteLwfRule(payload: any) {
        return this.webService.postFormData('company/delete-lwf-rule', payload);
      }

    geTDSList (payload: any) {
        return this.webService.post('company/get-tds-list', payload);
      }
    
    getTdsRuleLibrary(payload: any) {
        return this.webService.post('company/get-tds-library-list', payload);
      }
    
    getTDSAct(payload: any = '') {
        return this.webService.post('get-tds-act', payload);
      }

    gettdsTemplate(payload: any = '') {
        return this.webService.post('company/get-tds-data', payload);
      } 
    
    getsalarygovHeads(payload: any = '') {
        return this.webService.post('company/get-earning-temp-head', payload);
      }

    getsalaryHeads(payload: any = '') {
        return this.webService.post('company/get-salary-template-head', payload);
      }

    //staff api start
    fetchStaffPageMasters() {
        return this.webService.post('company/get-employee-master', {});
    }
    fetchStaffs(payload: any) {
        return this.webService.post('company/get_staff', payload);
    }
    updateStaff(payload: any) {
        return this.webService.postFormData('company/update_staff', payload);
    }
    updateStaffPassword(payload: any) {
        return this.webService.post('company/update_staff_password', payload);
    }
    deleteStaff(payload: any) {
        return this.webService.post('company/delete_staff', payload);
    }

    savetdsTemplate(payload: any = '') {
        return this.webService.post('company/add-tds-template-data', payload);
      }
    //staff api end

    //role access api start
    fetchStaffRoles(payload: any) {
        return this.webService.post('company/role-list', payload);
    }
    fetchPermissions(payload: any) {
        return this.webService.post('company/get_permission_module', payload);
    }
    createStaffRole(payload: any) {
        return this.webService.post('company/create_role', payload);
    }
    updateStaffRole(payload: any) {
        return this.webService.post('company/update_role', payload);
    }
    changeStaffRoleStatus(payload: any) {
        return this.webService.post('company/update_role_status', payload);
    }
    createStaff(payload: any) {
        return this.webService.postFormData('company/create_staff', payload);
    }
    fetchEmployeeRoles(payload: any) {
        return this.webService.post('company/get-emp-role-list', payload);
    }
    createEmployeeRole(payload: any) {
        return this.webService.post('company/add-emp-role', payload);
    }
    updateEmployeeRole(payload: any) {
        return this.webService.post('company/update-emp-role', payload);
    }
    changeEmployeeRoleStatus(payload: any) {
        return this.webService.post('company/update-emp-role-status', payload);
    }
    //role access api end
}
