import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.css']
})
export class MenuSidebarComponent implements OnInit {
  lang: any = "en"
  currentLanguage$?: Subscription
  translations: any;
  @ViewChildren('menuItem') menuItems!: QueryList<ElementRef>;
  constructor(
    private authService: AuthService,
    private langService: LanguageTranslateService,
    private router: Router,
    private settingsService:SettingsService
  ) { }
  routerChange(routeString: string) {
    this.lang = this.langService.getLangLocalStorage() ?? 'en';
    this.router.navigate([this.lang, routeString]);
    this.scrollToActiveItem();
  }
  logout() {
    this.authService.logout();
    this.settingsService.clearCompanyDetails();
  }
  isActive(routeString: string): boolean {
    const url = this.router.url.split("/");

    if (url[2] == routeString) {
      return true;
    }
    return false;
  }
  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      this.lang = this.langService.getLangLocalStorage();
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('layout', 'MenuSidebarComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
  }
  ngOnDestroy() {
    this.currentLanguage$?.unsubscribe();
  }
  ngAfterViewInit() {
    this.scrollToActiveItem();
  }
  scrollToActiveItem() {
    setTimeout(() => {
      const activeItem = this.menuItems.find(item => item.nativeElement.classList.contains('active'));
      if (activeItem) {
        activeItem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 0);
  }
}
