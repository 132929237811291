import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { ChangePasswordComponent } from './modal/change-password.component';
import { scan, Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  company_logo!:string;
  company_id!:string;
  com_data_subs$!:Subscription
  com_logo_subs$!:Subscription
  lang:string = 'en';
  currentLanguage$?: Subscription
  translations: any;
  user_data:any

  constructor(
    private authService:AuthService,
    private dialog: MatDialog,
    private langService: LanguageTranslateService,
    private appCompoent:AppComponent
  ){
    this.com_logo_subs$ = this.authService.com_logo$.subscribe((img:any)=>{
        this.company_logo = img;        
    })

    this.authService.getCompanyUserAccountDetails().then(res=>{           
      this.company_id = res?.company_det?._id;
     })
  }

  ngOnInit(): void {
    this.com_data_subs$ = this.appCompoent.com_user$.subscribe(res => {
       this.user_data = res;
    });

    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      this.lang = this.langService.getLangLocalStorage();
        return new Promise<boolean>(async (resolve, reject) => {
          await this.langService.loadTranslations('layout', 'ProfileComponent')?.then((res: any) => {
            this.translations = res;
            resolve(true);
          }).catch((err: any) => {
            console.error(`Failed to load translations`, err);
            reject(false);
          });
        })
      });
  }

  changePassword(empId: any) {    
    this.dialog.open(ChangePasswordComponent, {
      data: empId,
      width: '500px',
      autoFocus: false,
      disableClose: true,
    }).afterClosed().subscribe((res: any) => {
      if (res?.status == 'success') {
       
      }
    })
  }

  logout(){
    this.authService.logout();
  }
  ngOnDestroy(){
   this.com_data_subs$.unsubscribe();
   this.com_logo_subs$.unsubscribe();
   this.currentLanguage$?.unsubscribe();
  }
}
