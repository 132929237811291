
<div class="info-fluid height100">
    <div class="d-flex justify-content-center"  *ngIf="isLinkValid && isFormPending">
      <div class="bd_settingstep invitestep w-100">
        <div class="navline"></div>
        <ul class="stepnav">
          <li class="stepnavlink" (click)="employee_details ? formType = 'personal_details' : null" [class.active]="formType === 'personal_details'">
            <a >
              <span class="stround"></span>
              <i>Personal Details</i>
            </a>
          </li>
          <li class="stepnavlink" (click)="employee_details ? formType = 'address_details' : null" [class.active]="formType === 'address_details'">
            <a>
              <span class="stround"></span>
              <i>Address</i>
            </a>
          </li>
          <li class="stepnavlink" (click)="employee_details ? formType = 'bank_details' : null" [class.active]="formType === 'bank_details'">
            <a>
              <span class="stround"></span>
              <i>Bank Details</i>
            </a>
          </li>
          <li class="stepnavlink" (click)="employee_details ?  formType = 'pf_esi' : null" [class.active]="formType === 'pf_esi'">
            <a>
              <span class="stround"></span>
              <i>PF & ESI</i>
            </a>
          </li>

          <li class="stepnavlink" (click)="employee_details ? formType = 'educational' : null" [class.active]="formType === 'educational'">
            <a>
              <span class="stround"></span>
              <i>Educational</i>
            </a>
          </li>
          <li class="stepnavlink" (click)="employee_details ? formType = 'accident' : null" [class.active]="formType === 'accident'">
            <a>
              <span class="stround"></span>
              <i>Accident</i>
            </a>
          </li>
          <li class="stepnavlink" (click)="employee_details ? formType = 'training' : null" [class.active]="formType === 'training'">
            <a>
              <span class="stround"></span>
              <i>Training</i>
            </a>
          </li>
          <li class="stepnavlink" (click)="employee_details ? formType = 'extra_curricular' : null" [class.active]="formType === 'extra_curricular'">
            <a>
              <span class="stround"></span>
              <i>Extra Curricular</i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card info_box mw-100 w-75 m-auto"  *ngIf="isLinkValid && isFormPending">
        <ng-container *ngIf="formType === 'personal_details'">
         <div class="p-15">
        <h5 class="mb-4">{{translations?.employeeDetails}}</h5>
        <form [formGroup]="employeeDetailsForm">

       <div class="row ">
          <div class="col-md-12">
           <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                    <label >
                      {{translations?.employeeFirstName}} &nbsp;<span *ngIf="
                      Global.isValidationAvailable(
                        employeeDetailsForm,
                        'emp_first_name',
                        'required'
                      )
                    " class="text-danger">*</span>
                  </label>
                  
                    <input class="form-control" type="text" formControlName="emp_first_name" />
  
                    <p class="error-element" *ngIf="
                    !Global.isInputRuleValid(
                      employeeDetailsForm,
                      'emp_first_name',
                      'required'
                    )
                  ">
                      {{translations?.thisFieldIsRequired}}
                    </p>
                  </div>
             </div>
            
             <div class="col-md-4">
              <div class="form-group">
              <label>
                    {{translations?.employeeLastName}} &nbsp;<span *ngIf="
                  Global.isValidationAvailable(
                    employeeDetailsForm,
                    'emp_last_name',
                    'required'
                  )
                " class="text-danger">*</span>
              </label>
          
              <input class="form-control" type="text" formControlName="emp_last_name" />

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'emp_last_name',
                'required'
              )
            ">
                {{translations?.thisFieldIsRequired}}
              </p>
            </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
              <label>
            {{translations?.gender}} &nbsp;<span *ngIf="
              Global.isValidationAvailable(
                employeeDetailsForm,
                'sex',
                'required'
              )
            " class="text-danger">*</span>
            </label>
           
              <ngx-select-dropdown  style="background-color: #ffffff;" formControlName="sex" [options]="sexMaster" class="form-control border-0 p-0"
                [config]="{
              search: true,
              placeholder: 'Select Gender'
            }" [multiple]="false"></ngx-select-dropdown>

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'sex',
                'required'
              )
            ">
                {{translations?.thisFieldIsRequired}}
              </p>
            </div>
            </div>
            
            <div class="col-md-4">
              <div class="form-group">
              <label>
              {{translations?.officialMobile}} &nbsp;<span *ngIf="
              Global.isValidationAvailable(
                employeeDetailsForm,
                'mobile_no',
                'required'
              )
            " class="text-danger">*</span>
          </label>
          
              <input class="form-control" type="text" formControlName="mobile_no" />

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'mobile_no',
                'required'
              )
            ">
                {{translations?.thisFieldIsRequired}}
              </p>

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'mobile_no',
                'pattern'
              ) ||
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'mobile_no',
                'minlength'
              ) ||
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'mobile_no',
                'maxlength'
              )
            ">
                {{translations?.enterValidContactNumber}}
              </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
              <label>
                  {{translations?.alternateMobile}} &nbsp;<span *ngIf="
                  Global.isValidationAvailable(
                    employeeDetailsForm,
                    'alternate_mob_no',
                    'required'
                  )
                " class="text-danger">*</span>
              </label>
            
              <input class="form-control" type="text" formControlName="alternate_mob_no" />

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'alternate_mob_no',
                'required'
              )
            ">
                {{translations?.thisFieldIsRequired}}
              </p>

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'alternate_mob_no',
                'pattern'
              ) ||
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'alternate_mob_no',
                'minlength'
              ) ||
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'alternate_mob_no',
                'maxlength'
              )
            ">
                {{translations?.enterValidContactNumber}}
              </p>
            </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
             <label>
              {{translations?.officialEmailId}} &nbsp;<span *ngIf="
              Global.isValidationAvailable(
                employeeDetailsForm,
                'email_id',
                'required'
              )
            " class="text-danger">*</span>
             </label>
           
              <input class="form-control" type="email" formControlName="email_id" />

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'email_id',
                'required'
              )
            ">
                {{translations?.thisFieldIsRequired}}
              </p>

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'email_id',
                'email'
              )
            ">
                {{translations?.pleaseEnterValidEmail}}
              </p>
            </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
             <label>
              {{translations?.personalEmailId}} &nbsp;<span *ngIf="
              Global.isValidationAvailable(
                employeeDetailsForm,
                'personal_email_id',
                'required'
              )
            " class="text-danger">*</span>
             </label>
           
              <input class="form-control" type="email" formControlName="personal_email_id" />

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'personal_email_id',
                'required'
              )
            ">
                {{translations?.thisFieldIsRequired}}
              </p>

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'personal_email_id',
                'email'
              )
            ">
                {{translations?.pleaseEnterValidEmail}}
              </p>
            </div>
            </div>
  
            <div class="col-md-4">
              <div class="form-group">
              <label>
                  {{translations?.emergencyContactNumber}}  &nbsp;<span *ngIf="
                  Global.isValidationAvailable(
                    employeeDetailsForm,
                    'emergency_contact_no',
                    'required'
                  )
                " class="text-danger">*</span>
              </label>
           
              <input class="form-control" type="text" formControlName="emergency_contact_no" />
  
              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'emergency_contact_no',
                'required'
              )
            ">
                {{translations?.thisFieldIsRequired}}
              </p>
  
              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'emergency_contact_no',
                'pattern'
              ) ||
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'emergency_contact_no',
                'minlength'
              ) ||
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'emergency_contact_no',
                'maxlength'
              )
            ">
                {{translations?.enterValidContactNumber}}  
              </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>
                  {{translations?.emergencyContactName}}   &nbsp;<span *ngIf="
                  Global.isValidationAvailable(
                    employeeDetailsForm,
                    'emergency_contact_name',
                    'required'
                  )
                " class="text-danger">*</span>
              </label>
          
              <input class="form-control" type="text" formControlName="emergency_contact_name" />
  
              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'emergency_contact_name',
                'required'
              )
            ">
                {{translations?.thisFieldIsRequired}}
              </p>
  
            </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
              <label>
                  {{translations?.dateOfBirth}} &nbsp;<span *ngIf="
                  Global.isValidationAvailable(
                    employeeDetailsForm,
                    'emp_dob',
                    'required'
                  )
                " class="text-danger">*</span>
              </label>
            
              <input class="form-control" type="date" formControlName="emp_dob" />

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'emp_dob',
                'required'
              )
            ">
                {{translations?.thisFieldIsRequired}}
              </p>
            </div>
            </div>

            <div class="col-md-4">
                <div class="form-group">
                    <label>
                      {{translations?.fatherName}} &nbsp;<span *ngIf="
                      Global.isValidationAvailable(
                        employeeDetailsForm,
                        'emp_father_name',
                        'required'
                      )
                    " class="text-danger">*</span>
                    </label>
                  <input class="form-control" type="text" formControlName="emp_father_name" />
                  <p class="error-element" *ngIf="
                  !Global.isInputRuleValid(
                    employeeDetailsForm,
                    'emp_father_name',
                    'required'
                  )
                ">
                    {{translations?.thisFieldIsRequired}}
                  </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
            <label>{{translations?.motherName}} &nbsp;<span *ngIf="
              Global.isValidationAvailable(
                employeeDetailsForm,
                'emp_mother_name',
                'required'
              )
            " class="text-danger">*</span></label>
            
              <input class="form-control" type="text" formControlName="emp_mother_name" />

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'emp_mother_name',
                'required'
              )
            ">
                {{translations?.thisFieldIsRequired}}
              </p>
            </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>
                    {{translations?.nationality}} &nbsp;<span *ngIf="
                    Global.isValidationAvailable(
                      employeeDetailsForm,
                      'nationality',
                      'required'
                    )
                  " class="text-danger">*</span>
                </label>
            
              <input class="form-control" type="text" formControlName="nationality" />
  
              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'nationality',
                'required'
              )
            ">
                {{translations?.thisFieldIsRequired}}
              </p>
  
              <p class="error-element" *ngIf="employeeDetailsForm.get('nationality')?.hasError('pattern')">
                {{translations?.numbersNotAllowedInThisField}}
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>
                  {{translations?.domicile}} &nbsp;<span *ngIf="
                  Global.isValidationAvailable(
                    employeeDetailsForm,
                    'domicile',
                    'required'
                  )
                " class="text-danger">*</span>
              </label>
            
     
            <input class="form-control" type="text" formControlName="domicile" />

            <p class="error-element" *ngIf="
            !Global.isInputRuleValid(
              employeeDetailsForm,
              'domicile',
              'required'
            )
          ">
              {{translations?.thisFieldIsRequired}}
            </p>

            <p class="error-element" *ngIf="employeeDetailsForm.get('domicile')?.hasError('pattern')">
              {{translations?.numbersNotAllowedInThisField}}
            </p>
          </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
                <label>
                  {{translations?.bloodGroup}} &nbsp;<span *ngIf="
                  Global.isValidationAvailable(
                    employeeDetailsForm,
                    'blood_group',
                    'required'
                  )
                " class="text-danger">*</span>
                </label>
              
                <ngx-select-dropdown   formControlName="blood_group" [options]="bloodGroupMaster"
                  class="form-control p-0 border-0"
                  [config]="{ search: true, placeholder: 'Select One' }"></ngx-select-dropdown>


                <p class="error-element" *ngIf="
                !Global.isInputRuleValid(
                  employeeDetailsForm,
                  'blood_group',
                  'required'
                )
              ">
                  {{translations?.thisFieldIsRequired}}
                </p>
              </div>
          </div>


          <div class="col-md-4">
            <div class="form-group">
            <label>
              {{translations?.physicalDisablity}} &nbsp;<span *ngIf="
              Global.isValidationAvailable(
                employeeDetailsForm,
                'physical_disability',
                'required'
              )" class="text-danger">*</span>
           </label>
         
            <ngx-select-dropdown   formControlName="physical_disability" [options]="yesNoMaster"
              class="form-control p-0 border-0"
              [config]="{ search: true, placeholder: 'Select One' }"></ngx-select-dropdown>


            <p class="error-element" *ngIf="
            !Global.isInputRuleValid(
              employeeDetailsForm,
              'physical_disability',
              'required'
            )
          ">
              {{translations?.thisFieldIsRequired}}
            </p>
          </div>
          </div>
         
          <div class="col-md-4">
            <div class="form-group">
            <label>
                  {{translations?.aadharCardNumber}} &nbsp;<span *ngIf="
                  Global.isValidationAvailable(
                    employeeDetailsForm,
                    'aadhar_no',
                    'required'
                  )
                " class="text-danger">*</span>
            </label>
         
            <input class="form-control" type="text" formControlName="aadhar_no" />

            <p class="error-element" *ngIf="
            !Global.isInputRuleValid(
              employeeDetailsForm,
              'aadhar_no',
              'required'
            )
          ">
        
              {{translations?.thisFieldIsRequired}}
            </p>

            <p class="error-element" *ngIf="
            !Global.isInputRuleValid(
              employeeDetailsForm,
              'aadhar_no',
              'pattern'
            )||
            !Global.isInputRuleValid(
              employeeDetailsForm,
              'aadhar_no',
              'minlength'
            ) ||
            !Global.isInputRuleValid(
              employeeDetailsForm,
              'aadhar_no',
              'maxlength'
            )
          ">
              {{translations?.enterValidAadharNumber}}
            </p>

          </div>
          </div>
          <ng-container *ngIf="
            employeeDetailsForm.controls['aadhar_no'].value &&
            employeeDetailsForm.controls['aadhar_no'].value !=
              'N/A'
          ">
          <div class="col-md-4">
            <div class="form-group file-upload uploadfile">
         
          <label for="">
              {{translations?.aadharCardImage}}&nbsp;
              <span *ngIf="
              Global.isValidationAvailable(
                employeeDetailsForm,
                'emp_aadhaar_image',
                'required'
              )
            " class="text-danger">*</span>
            </label>
          
            <ng-container *ngIf="
            employeeDetailsForm.controls['aadhar_no'].value &&
            employeeDetailsForm.controls['aadhar_no'].value !=
              'N/A'
          ">
            

                <input class="form-control" type="file" accept=".jpeg, .jpg, .png, .pdf"
                  formControlName="emp_aadhaar_image" (change)="
                onFileChanged(
                  $event,
                  employeeDetailsForm,
                  'emp_aadhaar_image',
                  'emp_aadhaar_image_file'
                )" />
                <span *ngIf="!aadhar_filname">{{translations?.supportedFiles}}</span>
                <span *ngIf="aadhar_filname">{{aadhar_filname}}</span>
                 <!-- &nbsp;<span *ngIf="employee_details?.emp_aadhaar_image" (click)="
                  singleViewImage(employee_details?.emp_aadhaar_image)"><i class="bi bi-eye"></i></span> -->
                <p class="error-element" *ngIf="
                !Global.isInputRuleValid(
                  employeeDetailsForm,
                  'emp_aadhaar_image',
                  'required'
                )">
                  {{translations?.thisFieldIsRequired}}
                </p>

            </ng-container>
          </div>
          </div>
          </ng-container>
          <div class="col-md-4">
            <div class="form-group">
            <label>
                  {{translations?.panCardNumber}} &nbsp;<span *ngIf="
                  Global.isValidationAvailable(
                    employeeDetailsForm,
                    'pan_no',
                    'required'
                  )
                " class="text-danger">*</span>
            </label>
         
            <input class="form-control" type="text" formControlName="pan_no" />

            <p class="error-element" *ngIf="
            !Global.isInputRuleValid(
              employeeDetailsForm,
              'pan_no',
              'required'
            )
          ">
        
              {{translations?.thisFieldIsRequired}}
            </p>

            <p class="error-element" *ngIf="
            !Global.isInputRuleValid(
              employeeDetailsForm,
              'pan_no',
              'pattern'
            )
          ">
              {{translations?.enterValidPanNumber}}
            </p>

          </div>
          </div>
          <ng-container *ngIf="
            employeeDetailsForm.controls['pan_no'].value &&
            employeeDetailsForm.controls['pan_no'].value !=
              'N/A'
          ">
          <div class="col-md-4">
            <div class="form-group file-upload uploadfile">
         
          <label for="">
              {{translations?.panCardImage}}&nbsp;
              <span *ngIf="
              Global.isValidationAvailable(
                employeeDetailsForm,
                'emp_pan_image',
                'required'
              )
            " class="text-danger">*</span>
            </label>
          
            <ng-container *ngIf="
            employeeDetailsForm.controls['pan_no'].value &&
            employeeDetailsForm.controls['pan_no'].value !=
              'N/A'
          ">
            

                <input class="form-control" type="file" accept=".jpeg, .jpg, .png, .pdf"
                  formControlName="emp_aadhaar_image" (change)="
                onFileChanged(
                  $event,
                  employeeDetailsForm,
                  'emp_pan_image',
                  'emp_pan_image_file'
                )" />
                <span *ngIf="!pan_filname">{{translations?.supportedFiles}}</span>
                <span *ngIf="pan_filname">{{pan_filname}}</span>
                <!-- &nbsp; <span *ngIf="employee_details?.emp_pan_image" (click)=" -->
                  <!-- singleViewImage(employee_details?.emp_pan_image)"><i class="bi bi-eye"></i></span> -->
                <p class="error-element" *ngIf="
                !Global.isInputRuleValid(
                  employeeDetailsForm,
                  'emp_pan_image',
                  'required'
                )">
                  {{translations?.thisFieldIsRequired}}
                </p>

            </ng-container>
          </div>
          </div>
          </ng-container>
            <div class="col-md-4">
              <div class="form-group">
              <label>
                    {{translations?.passportNumber}} &nbsp;<span *ngIf="
                    Global.isValidationAvailable(
                      employeeDetailsForm,
                      'passport_no',
                      'required'
                    )" class="text-danger">*</span>
              </label>
           
              <input class="form-control" type="text" formControlName="passport_no" />

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'passport_no',
                'required'
              )">
          
                {{translations?.thisFieldIsRequired}}
              </p>

              <p class="error-element" *ngIf="
              !Global.isInputRuleValid(
                employeeDetailsForm,
                'passport_no',
                'pattern'
              )">
                {{translations?.enterValidPassportNumber}}
              </p>

            </div>
            </div>
         <ng-container *ngIf="
              employeeDetailsForm.controls['passport_no'].value &&
              employeeDetailsForm.controls['passport_no'].value !=
                'N/A'
            ">
            <div class="col-md-4">
              <div class="form-group file-upload uploadfile">
           
            <label for="">
                {{translations?.passportImage}}&nbsp;
                <span *ngIf="
                Global.isValidationAvailable(
                  employeeDetailsForm,
                  'emp_passport_image',
                  'required'
                )
              " class="text-danger">*</span>
              </label>
            
              <ng-container *ngIf="
              employeeDetailsForm.controls['passport_no'].value &&
              employeeDetailsForm.controls['passport_no'].value !=
                'N/A'">
              

                <ng-container >
                  <input class="form-control" type="file" accept=".jpeg, .jpg, .png, .pdf"
                    formControlName="emp_passport_image" (change)="
                  onFileChanged(
                    $event,
                    employeeDetailsForm,
                    'emp_passport_image',
                    'emp_passport_image_file'
                  )
                " />
                  <span *ngIf="!passport_filname">{{translations?.supportedFiles}}</span>
                  <span *ngIf="passport_filname">{{passport_filname}}</span>
                  <p class="error-element" *ngIf="
                  !Global.isInputRuleValid(
                    employeeDetailsForm,
                    'emp_passport_image',
                    'required'
                  )
                ">
                    {{translations?.thisFieldIsRequired}}
                  </p>
                </ng-container>

              
              </ng-container>
            </div>
            </div>
          </ng-container>

            <ng-container *ngIf="employeeDetailsForm.controls['passport_no'].value">
              <div class="col-md-4">
                <div class="form-group">
                <label for="">
                  {{translations?.passportValidFrom}} &nbsp;<span *ngIf="
                  Global.isValidationAvailable(
                    employeeDetailsForm,
                    'passport_val_form',
                    'required'
                  )
                " class="text-danger">*</span>
              </label>
             
                <input class="form-control" type="date" formControlName="passport_val_form" />
                <p class="error-element" *ngIf="
                !Global.isInputRuleValid(
                  employeeDetailsForm,
                  'passport_val_form',
                  'required'
                )
              ">
                  {{translations?.thisFieldIsRequired}}
                </p>
              </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                <label for="">
                {{translations?.passportValidTo}} &nbsp;<span *ngIf="
                Global.isValidationAvailable(
                  employeeDetailsForm,
                  'passport_val_to',
                  'required'
                )
              " class="text-danger">*</span>
                </label>
             
                <input class="form-control" type="date" formControlName="passport_val_to" />
                <p class="error-element" *ngIf="
                !Global.isInputRuleValid(
                  employeeDetailsForm,
                  'passport_val_to',
                  'required'
                )
              ">
                  {{translations?.thisFieldIsRequired}}
                </p>
               </div>
              </div>
            </ng-container>

            <!-- divider -->


          <div class="col-md-4">
            <div class="form-group">
                <label>
                    {{translations?.maritalStatus}} &nbsp;<span *ngIf="
                    Global.isValidationAvailable(
                      employeeDetailsForm,
                      'marital_status',
                      'required'
                    )
                  " class="text-danger">*</span>
                </label>
            
                <ngx-select-dropdown   formControlName="marital_status" [options]="maritalStatusMaster"
                  class="form-control p-0 border-0"
                  [config]="{ search: true, placeholder: 'Select One' }"></ngx-select-dropdown>
                <p class="error-element" *ngIf="
                !Global.isInputRuleValid(
                  employeeDetailsForm,
                  'marital_status',
                  'required'
                )
              ">
                  {{translations?.thisFieldIsRequired}}
                </p>
              </div>
          </div>

          <div class="col-md-4" *ngIf="
          employeeDetailsForm.controls['marital_status'].value?.value == 'married'">
              <div class="form-group">
                
                  <ng-container *ngIf="
                  employeeDetailsForm.controls['marital_status'].value
                    ?.value == 'married'">
                    <label>
                    {{translations?.dateOfMarriage}} &nbsp;<span *ngIf="
                    Global.isValidationAvailable(
                      employeeDetailsForm,
                      'marriage_date',
                      'required'
                    )
                  " class="text-danger">*</span> </label>
               
                  </ng-container>
                

              <ng-container *ngIf="
              employeeDetailsForm.controls['marital_status'].value?.value == 'married'
                
            ">
                <input class="form-control" type="date" formControlName="marriage_date" />

                <p class="error-element" *ngIf="
                !Global.isInputRuleValid(
                  employeeDetailsForm,
                  'marriage_date',
                  'required'
                )
              ">
                  {{translations?.thisFieldIsRequired}}
                </p>
              </ng-container>
              </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
                <label>
                  {{translations?.height}}&nbsp;<span *ngIf="
                  Global.isValidationAvailable(
                    employeeDetailsForm,
                    'height',
                    'required'
                  )
                " class="text-danger">*</span>
                </label>
            
                <input class="form-control" type="text" formControlName="height" />

                <p class="error-element" *ngIf="
                !Global.isInputRuleValid(
                  employeeDetailsForm,
                  'height',
                  'required'
                )
              ">
                  {{translations?.thisFieldIsRequired}}
                </p>
              </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
            <label>
                {{translations?.religion}} &nbsp;<span *ngIf="
                Global.isValidationAvailable(
                  employeeDetailsForm,
                  'religion',
                  'required'
                )
              " class="text-danger">*</span>
            </label>
          
            <ngx-select-dropdown   formControlName="religion" [options]="religionMaster"
              class="form-control p-0 border-0"
              [config]="{ search: true, placeholder: 'Select One' }"></ngx-select-dropdown>


            <p class="error-element" *ngIf="
            !Global.isInputRuleValid(
              employeeDetailsForm,
              'religion',
              'required'
            )
          ">
              {{translations?.thisFieldIsRequired}}
            </p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group file-upload uploadfile">
              <label>
                {{translations?.additionalId}}&nbsp;
                <span
                *ngIf="Global.isValidationAvailable(employeeDetailsForm, 'additional_id_image', 'required')"
                class="text-danger">*</span>
              </label>
          

         
              <ng-container >
                <input class="form-control" data-toggle="tooltip" data-placement="top" type="file"
                accept=".jpeg, .jpg, .png, .pdf" formControlName="additional_id_image"
                  (change)="onFileChanged($event, employeeDetailsForm, 'additional_id_image', 'additional_id_image_source')">
                  <span *ngIf="!additionalID_filname">{{translations?.supportedFiles}}</span>
                  <span *ngIf="additionalID_filname">{{additionalID_filname}}</span>
                <p class="error-element"
                  *ngIf="!Global.isInputRuleValid(employeeDetailsForm, 'additional_id_image', 'required')">
                  {{translations?.thisFieldIsRequired}}</p>
                <!-- <p class="error-element" *ngIf="net_uploaded_size>=max_upload_limit">{{translations?.maxUploadSizeExceed}} -->
                <!-- </p> -->
                <p class="error-element"
                  *ngIf="employeeDetailsForm.get('additional_id_image')?.hasError('fileSize')">{{translations?.fileSizeExceeds}} {{ employeeDetailsForm.get('additional_id_image')?.getError('maxSize') }} KB.</p>
              </ng-container>
           
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group file-upload uploadfile">

          <label>
            {{translations?.profileImage}}&nbsp;
            <span *ngIf="Global.isValidationAvailable(employeeDetailsForm, 'profile_image', 'required')"
              class="text-danger">*</span>
          </label>


       
            <ng-container>
              <input class="form-control" data-toggle="tooltip" data-placement="top" type="file" accept=".jpeg, .jpg, .png, .pdf"
                (change)="onFileChanged($event, employeeDetailsForm, 'profile_image', 'profile_image_file')">
                <span *ngIf="!profile_filname">{{translations?.supportedFiles}}</span>
                <span *ngIf="profile_filname">{{profile_filname}}</span>
            </ng-container>
 

          </div>
          </div>

           </div>
          </div>
       </div>
       
       <div class="col-md-12">
           <button *ngIf="!employee_details"
        class="btn button-solide gray-btn me-13" (click)="createEmployee($event)">Create</button>
           <button class="btn button-solide me-13" *ngIf="employee_details"
          (click)="updateEmployee($event)">Update</button>
          <button class="btn button-solide" *ngIf="employee_details" (click)="formType = 'address_details'">Next</button>

        </div>
    </form>
  </div>
      </ng-container>
   <ng-container *ngIf="formType === 'bank_details'">

   <div class="p-15">
    <h5 class="mb-4">{{translations?.employeeBankDetails}}</h5>

      <form [formGroup]="employeeBankForm">
      <div class="row">

          <div class="col-md-4">
              <div class="form-group">
                  <label>
                      {{translations?.bankName}}
                      &nbsp;<span *ngIf="Global.isValidationAvailable(employeeBankForm, 'bank_name', 'required')" class="text-danger">*</span>
                  </label>
                  <input class="form-control" type="text" formControlName="bank_name">
                  <p class="error-element static-text" *ngIf="!Global.isInputRuleValid(employeeBankForm, 'bank_name', 'required')">{{translations?.requiredValidation}}</p>
                  <p class="error-element static-text" *ngIf="!Global.isInputRuleValid(employeeBankForm, 'bank_name', 'pattern')">{{translations?.numberValidation}}</p>
              </div>
          </div>

          <div class="col-md-4">
              <div class="form-group">
                  <label>
                      {{translations?.branchName}}
                      &nbsp;<span *ngIf="Global.isValidationAvailable(employeeBankForm, 'branch_name', 'required')" class="text-danger">*</span>
                  </label>
                  <input class="form-control" type="text" formControlName="branch_name">
                  <p class="error-element static-text" *ngIf="!Global.isInputRuleValid(employeeBankForm, 'branch_name', 'required')">{{translations?.requiredValidation}}</p>
                  <p class="error-element static-text" *ngIf="!Global.isInputRuleValid(employeeBankForm, 'branch_name', 'pattern')">{{translations?.numberValidation}}</p>
              </div>
          </div>
     
          <div class="col-md-4">
              <div class="form-group">
                  <label>
                      {{translations?.branchAddress}}
                      &nbsp;<span *ngIf="Global.isValidationAvailable(employeeBankForm, 'branch_address', 'required')" class="text-danger">*</span>
                  </label>
                  <input class="form-control" type="text" formControlName="branch_address">
                  <p class="error-element static-text" *ngIf="!Global.isInputRuleValid(employeeBankForm, 'branch_address', 'required')">{{translations?.requiredValidation}}</p>
              </div>
          </div>
          <div class="col-md-4">
              <div class="form-group">
                  <label>
                      {{translations?.bankPIN}}
                      &nbsp;<span *ngIf="Global.isValidationAvailable(employeeBankForm, 'branch_pin', 'required')" class="text-danger">*</span>
                  </label>
                  <input class="form-control" type="text" formControlName="branch_pin">
                  <p class="error-element static-text" *ngIf="!Global.isInputRuleValid(employeeBankForm, 'branch_pin', 'required')">{{translations?.requiredValidation}}</p>
              </div>
          </div>
     
          <div class="col-md-4">
              <div class="form-group">
                  <label>
                      {{translations?.acNo}}
                      &nbsp;<span *ngIf="Global.isValidationAvailable(employeeBankForm, 'account_no', 'required')" class="text-danger">*</span>
                  </label>

                  <input class="form-control" type="text" formControlName="account_no">
                  <p class="error-element static-text" *ngIf="!Global.isInputRuleValid(employeeBankForm, 'account_no', 'required')">{{translations?.requiredValidation}}</p>
                  <p class="error-element static-text" *ngIf="(!Global.isInputRuleValid(employeeBankForm, 'account_no', 'pattern') || !Global.isInputRuleValid(employeeBankForm, 'account_no', 'minlength') || !Global.isInputRuleValid(employeeBankForm, 'account_no', 'maxlength'))">{{translations?.enterValidAcNo}}</p>
              </div>
          </div>

          <div class="col-md-4">
              <div class="form-group">
                  <label>
                      {{translations?.reEnterAcNo}}
                      &nbsp;<span *ngIf="Global.isValidationAvailable(employeeBankForm, 'account_no_confirmation', 'required')" class="text-danger">*</span>
                  </label>
                  <input class="form-control" type="text" formControlName="account_no_confirmation">

                  <p class="error-element static-text" *ngIf="!Global.isInputRuleValid(employeeBankForm, 'account_no_confirmation', 'required')">{{translations?.requiredValidation}}</p>

                  <p class="error-element static-text" *ngIf="(!Global.isInputRuleValid(employeeBankForm, 'account_no_confirmation', 'pattern') || !Global.isInputRuleValid(employeeBankForm, 'account_no_confirmation', 'minlength') || !Global.isInputRuleValid(employeeBankForm, 'account_no_confirmation', 'maxlength'))">{{translations?.enterValidAcNo}}</p>
              </div>
          </div>
     
          <div class="col-md-4">
              <div class="form-group">
                  <label>
                      {{translations?.acType}}
                      &nbsp;<span *ngIf="Global.isValidationAvailable(employeeBankForm, 'account_type', 'required')" class="text-danger">*</span>
                  </label>
                  <ngx-select-dropdown formControlName="account_type"  [options]="accountTypeMaster" class="form-control p-0 border-0" [config]="{search:false, placeholder: 'Select One'}" [multiple]="false"></ngx-select-dropdown>

                  <p class="error-element static-text" *ngIf="!Global.isInputRuleValid(employeeBankForm, 'account_type', 'required')">{{translations?.requiredValidation}}</p>
              </div>
          </div>

          <div class="col-md-4">
              <div class="form-group">

                  <label>
                      {{translations?.ifscCode}}
                      &nbsp;<span *ngIf="Global.isValidationAvailable(employeeBankForm, 'ifsc_code', 'required')" class="text-danger">*</span>
                  </label>

                  <input class="form-control" type="text" formControlName="ifsc_code">
                  <p class="error-element static-text" *ngIf="!Global.isInputRuleValid(employeeBankForm, 'ifsc_code', 'required')">{{translations?.requiredValidation}}</p>
             
              </div>
          </div>
          <div class="col-md-4">
              <div class="form-group">
                      <label>
                          {{translations?.swiftCode}}
                          &nbsp;<span *ngIf="Global.isValidationAvailable(employeeBankForm, 'swift_code', 'required')" class="text-danger">*</span>
                      </label>
                      <input class="form-control" type="text" formControlName="swift_code">
                      <p class="error-element static-text" *ngIf="!Global.isInputRuleValid(employeeBankForm, 'swift_code', 'required')">{{translations?.requiredValidation}}</p>
              </div>
          </div>
       
      
          <div class="col-md-4">
              <div class="form-group">
                  <label>
                      {{translations?.micrNo}}
                      &nbsp;<span *ngIf="Global.isValidationAvailable(employeeBankForm, 'micr_no', 'required')" class="text-danger">*</span>
                  </label>
                  <input class="form-control" type="text" formControlName="micr_no">
                  <p class="error-element static-text" *ngIf="!Global.isInputRuleValid(employeeBankForm, 'micr_no', 'required')">{{translations?.requiredValidation}}</p>
                  <p class="error-element static-text" *ngIf="(!Global.isInputRuleValid(employeeBankForm, 'micr_no', 'pattern') || !Global.isInputRuleValid(employeeBankForm, 'micr_no', 'minlength') || !Global.isInputRuleValid(employeeBankForm, 'micr_no', 'maxlength'))">{{translations?.enterValidMicrNo}}</p>
              </div>
          </div>
          <div class="col-md-4">
              <div class="form-group file-upload uploadfile">
                  <label>
                      {{translations?.uploadChequePassbook}}
                      &nbsp;<span *ngIf="Global.isValidationAvailable(employeeBankForm, 'cancel_cheque', 'required')" class="text-danger">*</span>
                  </label>
                  

                  <ng-container >
                      <input class="form-control" type="file" 
                      accept=".jpeg, .jpg, .png, .pdf" formControlName="cancel_cheque" (change)="onFileChanged($event, employeeBankForm, 'cancel_cheque', 'cancel_cheque_file')">
                      <span *ngIf="!cancel_cheque_fileName">{{translations?.supportedFiles}}</span>
                      <span *ngIf="cancel_cheque_fileName">{{cancel_cheque_fileName}}</span>
                      <p class="error-element static-text" *ngIf="!Global.isInputRuleValid(employeeBankForm, 'cancel_cheque', 'required')">{{translations?.requiredValidation}}</p>
                      <!-- <p class="error-element static-text" *ngIf="(max_upload_limit>0 && net_uploaded_size>max_upload_limit)">{{translations?.maxUploadSizeExceed}}</p> -->
                      <p class="error-element static-text" *ngIf="employeeBankForm.get('cancel_cheque')?.hasError('fileSize')"> {{translations?.fileSizeExceeds}} {{ employeeBankForm.get('cancel_cheque')?.getError('maxSize') }} KB.</p>


                  </ng-container>
             
              </div>
          </div>
      </div>
      
      <div class="row" >
          <div class="col-12 col-sm-12">
              <button class="btn button-solide text-uppercase me-13" (click)="updateEmployeeBankDetail($event)">
              <span >{{translations?.update}}</span>
             
              </button>
            <button class="btn button-solide" (click)="formType = 'pf_esi'">Next</button>

          </div>
      </div>
     </form>
  </div>
  </ng-container>
    <ng-container *ngIf="formType === 'address_details'">
      <div class="p-15">

    <h5 class="mb-4">{{translations?.empAddress}}</h5>

      <form [formGroup]="employeeAddressForm">
    <div class="row">

        <div class="col-md-4">
            <div class="form-group">
                <label>
                    {{translations?.residenceNo}}
                    &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'resident_no', 'required')" class="text-danger">*</span>
                </label>
        
                <input class="form-control" type="text" formControlName="resident_no">

                <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'resident_no', 'required')">{{translations?.requiredValidation}}</p>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
                <label>
        {{translations?.residenceName}}
            &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'residential_name', 'required')" class="text-danger">*</span>
        </label>
        
            <input class="form-control" type="text" formControlName="residential_name">

            <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'residential_name', 'required')">{{translations?.requiredValidation}}</p>
        </div>
        </div>
    
        <div class="col-md-4">
            <div class="form-group">
                <label>
                    {{translations?.roadRoad}}
                    &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'road', 'required')" class="text-danger">*</span>
                </label>
            
                <input class="form-control" type="text" formControlName="road">

                <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'road', 'required')">{{translations?.requiredValidation}}</p>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
                <label>
                    {{translations?.localityArea}}
                    &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'locality', 'required')" class="text-danger">*</span>
                </label>
        
                <input class="form-control" type="text" formControlName="locality">

                <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'locality', 'required')">{{translations?.requiredValidation}}</p>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
            <label>
                {{translations?.cityTown}}
                &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'city', 'required')" class="text-danger">*</span>
            </label>
      
            <input class="form-control" type="text" formControlName="city">

            <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'city', 'required')">{{translations?.requiredValidation}}</p>
            <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'city', 'pattern')">{{translations?.numbersValidation}}</p>
        </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
                <label>
                    {{translations?.district}}
                    &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'district', 'required')" class="text-danger">*</span>
                </label>
                <input class="form-control" type="text" formControlName="district">

                <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'district', 'required')">{{translations?.requiredValidation}}</p>
                <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'district', 'pattern')">{{translations?.numbersValidation}}</p>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
                <label>
                    {{translations?.state}}
                    &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'state', 'required')" class="text-danger">*</span>
                </label>
            
                <ngx-select-dropdown formControlName="state" [options]="stateMaster" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}"></ngx-select-dropdown>

                    <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'state', 'required')">{{translations?.requiredValidation}}</p>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
                <label>
                    {{translations?.pincode}}
                    &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'pincode', 'required')" class="text-danger">*</span>
                </label>
        
                <input class="form-control" type="text" formControlName="pincode">

                <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'pincode', 'required')">{{translations?.requiredValidation}}</p>

                <p class="error-element" *ngIf="(!Global.isInputRuleValid(employeeAddressForm, 'pincode', 'pattern') || !Global.isInputRuleValid(employeeAddressForm, 'pincode', 'minlength') || !Global.isInputRuleValid(employeeAddressForm, 'pincode', 'maxlength'))">{{translations?.enterValidPincode}}</p>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
            <label>
                {{translations?.country}}
                &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'country', 'required')" class="text-danger">*</span>
            </label>
      
            <input class="form-control" type="text" formControlName="country">

            <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'country', 'required')">{{translations?.requiredValidation}}</p>
            <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'country', 'pattern')">{{translations?.numbersValidation}}</p>
        </div>
        </div>

        <!-- <div class="col-6 col-sm-6 sameasfirst">
        </div> -->
        <div class="col-md-12">
            <div class="form-group">
              <div class="d-flex flex-wrap position-relative align-items-center">
                <h5 class="radiolabel mb-0" style="flex: none;"> {{translations?.isCurrentResidentialAddress}}
                    &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'diff_current_add', 'required')" class="text-danger">*</span> </h5>
                <label class="radio_container position-static ms-4 mb-0">
                  <input type="radio" formControlName="diff_current_add" value="yes" class="me-1"><span>{{translations?.yes}}</span>
                </label>
                <label class="radio_container position-static ms-4 mb-0">
                  <input type="radio" formControlName="diff_current_add" value="no"  class="me-1"><span>{{translations?.no}}</span>
                </label>
                <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'diff_current_add', 'required')">{{translations?.requiredValidation}}</p>
              </div>
            </div>
          </div>

    </div>

    <ng-container *ngIf="employeeAddressForm?.get('diff_current_add')?.value == 'yes'">
        <div id="current-address-fields">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>
                            {{translations?.currentResidenceNo}}
                            &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'curr_resident_no', 'required')" class="text-danger">*</span>
                        </label>
                        <input class="form-control" type="text" formControlName="curr_resident_no">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'curr_resident_no', 'required')">{{translations?.requiredValidation}}</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>
                            {{translations?.currentResidenceName}}
                            &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'curr_residential_name', 'required')" class="text-danger">*</span>
                        </label>
                        <input class="form-control" type="text" formControlName="curr_residential_name">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'curr_residential_name', 'required')">{{translations?.requiredValidation}}</p>
                    </div>
                </div>
            
                <div class="col-md-4">
                    <div class="form-group">
                        <label>
                            {{translations?.currentRoadRoad}}
                            &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'curr_road', 'required')" class="text-danger">*</span>
                        </label>
                        <input class="form-control" type="text" formControlName="curr_road">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'curr_road', 'required')">{{translations?.requiredValidation}}</p>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>
                            {{translations?.currentLocalityArea}}
                            &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'curr_locality', 'required')" class="text-danger">*</span>
                        </label>
                        <input class="form-control" type="text" formControlName="curr_locality">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'curr_locality', 'required')">{{translations?.requiredValidation}}</p>
                    </div>
                </div>
            
                <div class="col-md-4">
                    <div class="form-group">
                        <label>
                            {{translations?.currentCity}}
                            &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'curr_city', 'required')" class="text-danger">*</span>
                        </label>
                    
                        <input class="form-control" type="text" formControlName="curr_city">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'curr_city', 'required')">{{translations?.requiredValidation}}</p>
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'curr_city', 'pattern')">{{translations?.numbersValidation}}</p>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>
                            {{translations?.currentDistrict}}
                            &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'curr_district', 'required')" class="text-danger">*</span>
                        </label>

                        <input class="form-control" type="text" formControlName="curr_district">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'curr_district', 'required')">{{translations?.requiredValidation}}</p>
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'curr_district', 'pattern')">{{translations?.numbersValidation}}</p>
                    </div>
                </div>
            
                <div class="col-md-4">
                    <div class="form-group">
                        <label>
                            {{translations?.currentState}}
                            &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'curr_state', 'required')" class="text-danger">*</span>
                        </label>

                        <ngx-select-dropdown formControlName="curr_state" [options]="stateMaster" class="form-control p-0 border-0" [config]="{search: true, placeholder: 'Choose One', 'height': '200px'}"></ngx-select-dropdown>
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'curr_state', 'required')">{{translations?.requiredValidation}}</p>
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'curr_state', 'pattern')">{{translations?.numbersValidation}}</p>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>
                            {{translations?.currentPincode}}
                            &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'curr_pincode', 'required')" class="text-danger">*</span>
                        </label>

                        <input class="form-control" type="text" formControlName="curr_pincode">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'curr_pincode', 'required')">{{translations?.requiredValidation}}</p>
                        <p class="error-element" *ngIf="(!Global.isInputRuleValid(employeeAddressForm, 'curr_pincode', 'pattern') || !Global.isInputRuleValid(employeeAddressForm, 'pincode', 'minlength') || !Global.isInputRuleValid(employeeAddressForm, 'pincode', 'maxlength'))">{{translations?.enterValidPincode}}</p>
                    </div>
                </div>
            
                <div class="col-md-4">
                    <div class="form-group">
                        <label>
                            {{translations?.currentCountry}}
                            &nbsp;<span *ngIf="Global.isValidationAvailable(employeeAddressForm, 'curr_country', 'required')" class="text-danger">*</span>
                        </label>

                        <input class="form-control" type="text" formControlName="curr_country">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'curr_country', 'required')">{{translations?.requiredValidation}}</p>
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAddressForm, 'curr_country', 'pattern')">{{translations?.numbersValidation}}</p>
                    </div>  
                </div>

                <div class="col-12 col-lg-6">
                </div>
            </div>
        </div>
    </ng-container>
    

    <div class="row">
        <div class="col-12 col-sm-12">
            <button class="btn button-solide me-13" (click)="updateEmployeeAddress($event)"><span>{{translations?.update}}</span></button>
            <button class="btn button-solide" (click)="formType = 'bank_details'">Next</button>
        </div>
    </div>
      </form>

     </div>
     </ng-container>
    <ng-container *ngIf="formType === 'pf_esi'">
      <div class="p-15">
        <h5 class="mb-4">PF & ESI Details</h5>
        <form [formGroup]="employmentForm">
          <div class="row">
          <div class="col-12">          
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div class="d-flex align-items-center swicharbtn">
                    <h5 class="radiolabel mb-0" style="flex: none;">Previous Employment</h5>
                    {{showPreviousEmp ? 'df':'else'}}
                    <label class="switch mb-0 position-relative top-0" (change)="shuffleFields()">
                      <input type="checkbox" [attr.checked]="showPreviousEmp ? true : null" ><span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="showPreviousEmp">
              <ng-container formGroupName="pre_er_details">
                 <div class="col-md-4">
                <div class="form-group">
                  <label>Name of Prev Employer
                    &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'pre_er_details.er_name', 'required')" class="text-danger">*</span>
                  </label>
                  <input type="text" placeholder="" formControlName="er_name" class="form-control">
                  <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'pre_er_details.er_name', 'required')">This field is required</p>
                </div>
                 </div>
                 <div class="col-md-4">
                <div class="form-group">
                  <label>Exit Date at Prev Employer
                    &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'pre_er_details.exit_date', 'required')" class="text-danger">*</span>
                  </label>
                  <input type="date" placeholder="" class="form-control" formControlName="exit_date">
                  <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'pre_er_details.exit_date', 'required')">This field is required</p>
                </div>
                 </div>
                 <div class="col-md-4">
                <div class="form-group">
                  <label>Last Drawn Gross
                    &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'pre_er_details.last_drawn_gross', 'required')" class="text-danger">*</span>
                  </label>
                  <input type="number" placeholder="" class="form-control" formControlName="last_drawn_gross">
                  <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'pre_er_details.last_drawn_gross', 'required')">This field is required</p>
                </div>
                 </div>
                 <div class="col-md-4">
                <div class="form-group">
                  <label>Last Designation
                    &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'pre_er_details.last_designation', 'required')" class="text-danger">*</span>
                  </label>
                  <input type="text" placeholder="" class="form-control" formControlName="last_designation">
                  <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'pre_er_details.last_designation', 'required')">This field is required</p>
                </div>
                 </div>
                 <div class="col-md-4">
                <div class="form-group">
                  <label>Reporting to
                    &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'pre_er_details.reporting_to', 'required')" class="text-danger">*</span>
                  </label>
                  <input type="text" placeholder="" class="form-control" formControlName="reporting_to">
                  <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'pre_er_details.reporting_to', 'required')">This field is required</p>
                </div>
                 </div>
                 <div class="col-md-4">
                <div class="form-group">
                  <label>Contact No
                    &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'pre_er_details.contact_no', 'required')" class="text-danger">*</span>
                  </label>
                  <input type="text" placeholder="" class="form-control" formControlName="contact_no">
                  <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'pre_er_details.contact_no', 'required')">This field is required</p>
                  <p class="error-element" *ngIf="(!Global.isInputRuleValid(employmentForm, 'pre_er_details.contact_no', 'pattern') || !Global.isInputRuleValid(employmentForm, 'pre_er_details.contact_no', 'minlength') || !Global.isInputRuleValid(employmentForm, 'pre_er_details.contact_no', 'maxlength'))">Enter valid contact number</p>

                </div>
                 </div> 
              </ng-container>
              <div class="col-12">
                <h6 class="mb-4">Previous EPFO Details</h6>
              </div>    
              <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                      <label>UAN No
                        &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'pre_er_epfo_details.uan_no', 'required')" class="text-danger">*</span>
                      </label>
                      <ng-container formGroupName="pre_er_epfo_details">
                          <input type="text" placeholder="" class="form-control" formControlName="uan_no">
                          <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'pre_er_epfo_details.uan_no', 'required')">This field is required</p>
                      </ng-container>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                      <label>Last Member ID
                        &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'pre_er_epfo_details.last_member_id', 'required')" class="text-danger">*</span>
                      </label>
                      <ng-container formGroupName="pre_er_epfo_details">
                        <input class="form-control" type="text" formControlName="last_member_id">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'pre_er_epfo_details.last_member_id', 'required')">This field is required</p>
                      </ng-container>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                      <label>Last RO
                        &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'pre_er_epfo_details.last_ro', 'required')" class="text-danger">*</span>
                      </label>
                      <ng-container formGroupName="pre_er_epfo_details">
                        <input class="form-control" type="text" formControlName="last_ro">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'pre_er_epfo_details.last_ro', 'required')">This field is required</p>
                    </ng-container>
                    </div>
                </div>
              </div> 
              <div class="col-12">
                <h6 class="mb-4">Previous ESIC Details</h6>
              </div>
              <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                      <label>IP/ESIC No
                        &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'pre_er_esic_details.esic_no', 'required')" class="text-danger">*</span>
                      </label>
                      <ng-container formGroupName="pre_er_esic_details">
                         <input type="text" placeholder="" class="form-control" formControlName="esic_no">
                         <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'pre_er_esic_details.esic_no', 'required')">This field is required</p>
                      </ng-container>
                    </div>
                  
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                      <label>IP Dispensary
                        &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'pre_er_esic_details.ip_dispensary', 'required')" class="text-danger">*</span>
                      </label>
                      <ng-container formGroupName="pre_er_esic_details">
                        <input class="form-control" type="text" formControlName="ip_dispensary">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'pre_er_esic_details.ip_dispensary', 'required')">This field is required</p>
                    </ng-container>
                    </div>
                  </div>
               
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Family Dispensary
                        &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'pre_er_esic_details.family_dispensary', 'required')" class="text-danger">*</span>
                      </label>
                      <ng-container formGroupName="pre_er_esic_details">
                          <input type="text" placeholder="" class="form-control" formControlName="family_dispensary">
                          <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'pre_er_esic_details.family_dispensary', 'required')">This field is required</p>
                      </ng-container>
                    </div>
                  </div>
              </div> 
            </ng-container>
            </div>
          </div>
  
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <p class="mb-4"><strong>Current Employer Details</strong></p>
              </div>
              <div class="col-12">
                <h6 class="mb-4">EPFO Details</h6>
              </div> 
              <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                      <label>UAN No
                        &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'curr_er_epfo_details.uan_no', 'required')" class="text-danger">*</span>
                      </label>
                      <ng-container formGroupName="curr_er_epfo_details">
                          <input type="text" placeholder="" formControlName="uan_no" class="form-control">
                          <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'curr_er_epfo_details.uan_no', 'required')">This field is required</p>
                      </ng-container>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                      <label>Last Member ID
                        &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'curr_er_epfo_details.last_member_id', 'required')" class="text-danger">*</span>
                      </label>
                      <ng-container formGroupName="curr_er_epfo_details">
                          <input type="text" placeholder="" class="form-control" formControlName="last_member_id">
                          <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'curr_er_epfo_details.last_member_id', 'required')">This field is required</p>
                      </ng-container>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                      <label>Last RO
                        &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'curr_er_epfo_details.last_ro', 'required')" class="text-danger">*</span>
                      </label>
                      <ng-container formGroupName="curr_er_epfo_details">
                          <input type="text" placeholder="" class="form-control">
                          <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'curr_er_epfo_details.last_ro', 'required')">This field is required</p>
                      </ng-container>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                      <label>Date of Membership as per portal
                        &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'curr_er_epfo_details.membership_date', 'required')" class="text-danger">*</span>
                      </label>
                      <ng-container formGroupName="curr_er_epfo_details">
                      <input type="date" placeholder="" class="form-control" formControlName="membership_date">
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'curr_er_epfo_details.membership_date', 'required')">This field is required</p>
                    </ng-container>
                    </div>
                 </div>
              </div>
              <div class="col-12">
                <h6 class="mb-4">ESIC Details</h6>
              </div> 
              <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                      <label>IP/ESIC No
                        &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'curr_er_esic_details.esic_no', 'required')" class="text-danger">*</span>
                      </label>
                      <ng-container formGroupName="curr_er_esic_details">
                          <input type="text" placeholder="" class="form-control" formControlName="esic_no">
                          <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'curr_er_esic_details.esic_no', 'required')">This field is required</p>
                      </ng-container>
                    </div>
                  </div>
                
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>IP Dispensary
                      &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'curr_er_esic_details.ip_dispensary', 'required')" class="text-danger">*</span>
                      </label>
                      <ng-container formGroupName="curr_er_esic_details">
                          <input type="text" placeholder="" class="form-control">
                          <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'curr_er_esic_details.ip_dispensary', 'required')">This field is required</p>
                      </ng-container>
                    </div>
                  </div>
                 
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Family Dispensary
                        &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'curr_er_esic_details.family_dispensary', 'required')" class="text-danger">*</span>
                      </label>
                      <ng-container formGroupName="curr_er_esic_details">
                        <input type="text" placeholder="" class="form-control" formControlName="family_dispensary">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'curr_er_esic_details.family_dispensary', 'required')">This field is required</p>  
                      </ng-container>
                    </div>
                  </div>
                
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Date of Membership as per portal 
                        &nbsp;<span *ngIf="Global.isValidationAvailable(employmentForm, 'curr_er_esic_details.membership_date', 'required')" class="text-danger">*</span>
                     </label>
                     <ng-container formGroupName="curr_er_esic_details">
                        <input type="date" placeholder="" class="form-control" formControlName="membership_date">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(employmentForm, 'curr_er_esic_details.membership_date', 'required')">This field is required</p>  
                     </ng-container>
                    </div>
                  </div>
              </div>
           
            </div>
          </div>
          <div class="col-lg-12">
            <div class="p-15 px-0">
                <div class="d-flex flex-wrap align-items-center justify-content-between">
                    <h6 class="mb-0">Family Details For ESIC</h6>
                    <button class="btn button-solide btn-border" (click)="addFormRows(employmentForm, 'esic_family_details')">+Add Family Member</button>
                </div>
              </div>
              <div class="table-responsive table-left">
                <table class="table table-bordered shift-allowance">
                    <tbody>
          
                      <tr>
                        <td style="background-color: #F5F6FA; width: 12%; text-align: left;">Name</td>
                        <td style="background-color: #F5F6FA; width: 12%; text-align: left;">DOB</td>
                        <td style="background-color: #F5F6FA; width: 12%; text-align: left;">Relation</td>
                        <td style="background-color: #F5F6FA; width: 12%; text-align: left;">Sex</td>
                        <td style="background-color: #F5F6FA; width: 12%; text-align: center;">Residing With IP</td>
                        <td style="background-color: #F5F6FA; width: 5%; text-align: center;"></td>
                    </tr>

                    <ng-container formArrayName="esic_family_details">
                      <tr *ngFor="let item of Global.getFormGroupArray(employmentForm, 'esic_family_details'); let i = index" [formGroupName]="i">
                        <td>
                            <div class="form-group mb-0">
                                <input formControlName="fm_name" type="text" class="form-control" placeholder="Your family member's name" 
                                [class.is-invalid]="!['VALID', 'DISABLED'].includes(item.get('fm_name')!.status) && 
                                ( item.get('fm_name')!.dirty || item.get('fm_name')!.touched )">
                                <p *ngIf="item.get('fm_name')?.hasError('required') && (item.get('fm_name')?.dirty || item.get('fm_name')?.touched)"
                                  class="invalid-feedback">
                                  Name is required
                                </p>
                            </div>
                        </td>
                        <td style="text-align: left;">
                            <div class="form-group mb-0">
                                <input formControlName="fm_dob" type="date" class="form-control" [class.is-invalid]="!['VALID', 'DISABLED'].includes(item.get('fm_dob')!.status) && ( item.get('fm_dob')!.dirty || item.get('fm_dob')!.touched )" placeholder="">
                                <p *ngIf="item.get('fm_dob')?.hasError('required') && (item.get('fm_dob')?.dirty || item.get('fm_dob')?.touched)" class="invalid-feedback">
                                  Date of Birth is required
                              </p>
                            </div>
                        </td>
                        <td style="text-align: left;">
                          <ngx-select-dropdown formControlName="fm_relation" [options]="relationMaster" class="px-3 form-control" [config]="{search:true, placeholder: 'Select Relation'}" [class.is-invalid]="!['VALID', 'DISABLED'].includes(item.get('fm_relation')!.status) 
                          && ( item.get('fm_relation')!.dirty || item.get('fm_relation')!.touched )"></ngx-select-dropdown>
                        </td>
                        <td style="text-align: left;">
                          <ngx-select-dropdown formControlName="sex" [options]="sexMaster" class="px-3 form-control" [config]="{search: false, placeholder: 'Select Gender'}" [class.is-invalid]="!['VALID', 'DISABLED'].includes(item.get('sex')!.status) && ( item.get('sex')!.dirty || item.get('sex')!.touched )"></ngx-select-dropdown>
                        </td>
                        <td style="text-align: center;">
                            <div class="form-group mb-0 ">
                                <label class="radio_container position-static justify-content-center mb-0">
                                    <input type="checkbox" class="h-auto" formControlName="residing_with_if" >
                                </label>
                            </div>
                        </td>
                        <td style="text-align: center;">
                          <button class="btn button_action red_action mx-auto" (click)="Global.removeFormGroupArrRow(employmentForm, 'esic_family_details', i)">
                            <i class="bi bi-trash-fill"></i>  
                          </button>
                        </td>
                      </tr>
                      <tr *ngIf="Global.getFormGroupArray(employmentForm, 'esic_family_details').length == 0">
                        <td colspan="100%" class="text-center">No records found</td>
                    </tr>
                    </ng-container>
                    </tbody>
                </table>
              </div>
           
        </div>

        <div class="col-lg-12">
          <div class="p-15 px-0">
              <div class="d-flex flex-wrap align-items-center justify-content-between">
                  <h6 class="mb-0">Nominee Details For PF</h6>
                  <button class="btn button-solide btn-border" (click)="addFormRows(employmentForm, 'pf_nominee_details')">+Add Family Member</button>
              </div>
            </div>
            <div class="table-responsive table-left">
              <table class="table table-bordered shift-allowance">
                  <tbody>
        
                    <tr>
                      <td style="background-color: #F5F6FA; width: 12%; text-align: left;">Name</td>
                      <td style="background-color: #F5F6FA; width: 12%; text-align: left;">Aadhaar</td>
                      <td style="background-color: #F5F6FA; width: 12%; text-align: left;">DOB</td>
                      <td style="background-color: #F5F6FA; width: 12%; text-align: left;">Sex</td>
                      <td style="background-color: #F5F6FA; width: 12%; text-align: left;">Relation</td>
                      <td style="background-color: #F5F6FA; width: 20%; text-align: center;">Address</td>
                      <td style="background-color: #F5F6FA; width: 7%; text-align: center;"></td>
                  </tr>
                  <ng-container formArrayName="pf_nominee_details">
                    <tr *ngFor="let item of Global.getFormGroupArray(employmentForm, 'pf_nominee_details'); let i = index" [formGroupName]="i">
                      <td>
                          <div class="form-group mb-0">
                              <input formControlName="name" type="text" class="form-control" placeholder="Your family member's name" [class.is-invalid]="!['VALID', 'DISABLED'].includes(item.get('name')!.status) && ( item.get('name')!.dirty || item.get('name')!.touched )">
                              <p *ngIf="item.get('name')?.hasError('required') && (item.get('name')?.dirty || item.get('name')?.touched)"
                              class="invalid-feedback">
                              Name is required
                             </p>
                             <p *ngIf="item.get('name')?.hasError('pattern') && (item.get('name')?.dirty || item.get('name')?.touched)"
                               class="invalid-feedback">
                              Number not allowed
                             </p>
                          </div>
                      </td>
                      <td>
                        <div class="form-group mb-0">
                            <input formControlName="aadhar_no" type="text" class="form-control" placeholder="Aadhaar No." [class.is-invalid]="!['VALID', 'DISABLED'].includes(item.get('aadhar_no')!.status) && ( item.get('aadhar_no')!.dirty || item.get('aadhar_no')!.touched )">
                            <p *ngIf="item.get('aadhar_no')?.hasError('pattern') && (item.get('aadhar_no')?.dirty || item.get('aadhar_no')?.touched)"
                                class="invalid-feedback">
                                Alphabet not allowed
                           </p>
                           <p *ngIf="item.get('aadhar_no')?.hasError('required') && (item.get('aadhar_no')?.dirty || item.get('aadhar_no')?.touched)"
                              class="invalid-feedback">
                              Adhar is required
                           </p>
                        </div>
                    </td>
                      <td style="text-align: left;">
                          <div class="form-group mb-0">
                              <input formControlName="dob" type="date" class="form-control" placeholder="" [class.is-invalid]="!['VALID', 'DISABLED'].includes(item.get('dob')!.status) && ( item.get('dob')!.dirty || item.get('dob')!.touched )">
                          </div>
                      </td>
                      <td style="text-align: left;">
                        <ngx-select-dropdown formControlName="sex" [options]="sexMaster" class="px-3 form-control" [config]="{search: false, placeholder: 'Select Gender'}" [class.is-invalid]="!['VALID', 'DISABLED'].includes(item.get('sex')!.status) && ( item.get('sex')!.dirty || item.get('sex')!.touched )"></ngx-select-dropdown>
                      </td>
                      <td style="text-align: left;">
                        <ngx-select-dropdown formControlName="fm_relation" [options]="relationMaster" class="px-3 form-control" [config]="{search:true, placeholder: 'Select Relation'}" [class.is-invalid]="!['VALID', 'DISABLED'].includes(item.get('fm_relation')!.status) && ( item.get('fm_relation')!.dirty || item.get('fm_relation')!.touched )"></ngx-select-dropdown>

                      </td>
                      <td style="text-align: left;">
                        <div class="form-group mb-0">
                            <input formControlName="address" type="text" class="form-control" placeholder="Address" [class.is-invalid]="!['VALID', 'DISABLED'].includes(item.get('address')!.status) && ( item.get('address')!.dirty || item.get('address')!.touched )">
                        </div>
                      </td>
                      <td style="text-align: center;">
                        <button class="btn button_action red_action mx-auto" (click)="Global.removeFormGroupArrRow(employmentForm, 'pf_nominee_details', i)">
                          <i class="bi bi-trash-fill"></i>  
                        </button>
                      </td>
                    </tr>
                    <tr *ngIf="Global.getFormGroupArray(employmentForm, 'pf_nominee_details').length == 0">
                      <td colspan="100%" class="text-center">No records found</td>
                  </tr>
                    </ng-container>
                  </tbody>
              </table>
            </div>
        
      </div>
        
          
          <div class="col-md-12 mt-3">
            <button class="btn button-solide me-13" (click)="updateEmployeeOtherDetail($event)">Update</button>
            <button class="btn button-solide" (click)="formType = 'educational'">Next</button>
          </div>
          </div>
        </form>
      </div>
    </ng-container>
    <ng-container *ngIf="formType === 'educational'">

        <div class="p-15">

         <h5 class="mb-4" >{{translations?.eduDetails}}</h5>
         <div class="p-15" id="education-submit-section">
                         <form [formGroup]="employeeEducationForm" >
            <div class="row">
                 <div class="col-md-4">
                    <div class="form-group">
                 <label>
                    {{translations?.institute}}&nbsp;
                    <span *ngIf="Global.isValidationAvailable(employeeEducationForm, 'institute', 'required')" class="text-danger">*</span>
                </label>
                    <input class="form-control" type="text" formControlName="institute">

                    <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeEducationForm, 'institute', 'required')">{{translations?.reqValid}}</p>
                    <!-- <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeEducationForm, 'institute', 'pattern')">Numbers not allowed</p> -->
                </div>
            </div>
                <div class="col-md-4 ">
                    <div class="form-group">

                <label >
                    {{translations?.university}}&nbsp;
                    <span *ngIf="Global.isValidationAvailable(employeeEducationForm, 'university', 'required')" class="text-danger">*</span>
                </label>
                    <input class="form-control" type="text" formControlName="university">

                    <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeEducationForm, 'university', 'required')">{{translations?.reqValid}}</p>
                    <!-- <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeEducationForm, 'university', 'pattern')">Numbers not allowed</p> -->
                </div>
            </div>

                <div class="col-md-4">
                    <div class="form-group">
                <label>
                    {{translations?.stream}}&nbsp;
                    <span *ngIf="Global.isValidationAvailable(employeeEducationForm, 'stream', 'required')" class="text-danger">*</span>
                </label>
                    <input class="form-control" type="text" formControlName="stream">

                    <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeEducationForm, 'stream', 'required')">{{translations?.reqValid}}</p>
                </div>
            </div>

                <div class="col-md-4 sameasfirst">
                    <div class="form-group">
                <label>
                    {{translations?.level}}&nbsp;
                    <span *ngIf="Global.isValidationAvailable(employeeEducationForm, 'level', 'required')" class="text-danger">*</span>
                </label>
                    <input class="form-control" type="text" formControlName="level">

                    <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeEducationForm, 'level', 'required')">{{translations?.reqValid}}</p>
                </div>
            </div>

                <div class="col-md-4">
                    <div class="form-group">
                <label>
                    {{translations?.specialisation}}&nbsp;
                    <span *ngIf="Global.isValidationAvailable(employeeEducationForm, 'specialisation', 'required')" class="text-danger">*</span>
                </label>
                    <input class="form-control" type="text" formControlName="specialisation">

                    <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeEducationForm, 'specialisation', 'required')">{{translations?.reqValid}}</p>
                    <!-- <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeEducationForm, 'specialisation', 'pattern')">Numbers not allowed</p> -->
                </div>
            </div>
                <div class="col-md-4 sameasfirst">
                    <div class="form-group">
                <label>
                    {{translations?.completion}}&nbsp;
                    <span *ngIf="Global.isValidationAvailable(employeeEducationForm, 'completion', 'required')" class="text-danger">*</span>
                </label>
                    <input class="form-control" type="date" formControlName="completion">

                    <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeEducationForm, 'completion', 'required')">{{translations?.reqValid}}</p>
                </div>
               </div>

                <div class="col-md-4">
                    <div class="form-group file-upload uploadfile">
                <label>
                    {{translations?.educationFileImage}}&nbsp;
                    <span *ngIf="Global.isValidationAvailable(employeeEducationForm, 'education_file_image', 'required')" class="text-danger">*</span>
                </label>
                        <input class="form-control" type="file" accept="image/*" formControlName="education_file_image" (change)="onFileChanged($event, employeeEducationForm, 'education_file_image', 'education_file_image_file')">
                        <p class="error-element" *ngIf="employeeEducationForm.controls['education_file_image'].hasError('required') && ( employeeEducationForm.controls['education_file_image'].dirty || employeeEducationForm.controls['education_file_image'].touched )">{{translations?.reqValid}}</p>
                        <!-- <p class="error-element" *ngIf="(max_upload_limit>0 && net_uploaded_size>max_upload_limit)">{{translations?.maxFile}}</p> -->
                        <p class="error-element" *ngIf="employeeEducationForm.get('education_file_image')?.hasError('fileSize')">
                            {{translations?.fileExceed}} {{ employeeEducationForm.get('education_file_image')?.getError('maxSize') }} KB.</p>
                            <span *ngIf="!education_filename">{{translations?.supportedFiles}}</span>
                            <span *ngIf="education_filename">{{education_filename}}</span>
                </div>
            </div>
        </div>
                <div class="col-sm-6"></div>
            <div class="row bd-1">
                <div class="col-12 col-sm-12">
                    <button type="button" class="btn button-solide gray-btn me-2" (click)="cancelEntry()">{{translations?.cancel}}</button>
                    <button type="submit" class="btn button-solide me-2" (click)="updateEmployeeEducationDetails($event)">
                    <span>{{translations?.update}}</span>
                    </button>
                </div>
            </div>
          </form>
         </div>
         <div class="col-lg-12">

           <div class="table-responsive table-left mt-3">
               <table class="table table-bordered shift-allowance">
                <tbody>
                    <tr>
                        <td style="background-color: #F5F6FA; width: 5%; text-align: left;">{{translations?.sl}}</td>
                        <td style="background-color: #F5F6FA; width: 5%; text-align: left;">{{translations?.institute}}</td>
                        <td style="background-color: #F5F6FA; width: 5%; text-align: left;">{{translations?.university}}</td>
                        <td style="background-color: #F5F6FA; width: 5%; text-align: left;">{{translations?.stream}}</td>
                        <td style="background-color: #F5F6FA; width: 5%; text-align: left;">{{translations?.level}}</td>
                        <td style="background-color: #F5F6FA; width: 5%; text-align: left;">{{translations?.specialisation}}</td>
                        <td style="background-color: #F5F6FA; width: 5%; text-align: left;">{{translations?.completion}}</td>                            
                        <td style="background-color: #F5F6FA; width: 5%; text-align: center;">{{translations?.action}}</td>
                    </tr>
                         <ng-container *ngIf="employee_details?.emp_det?.education?.length > 0; else noTableFound">
                         <tr *ngFor="let item of employee_details?.emp_det?.education; let i = index">
                             <td scope="row">{{ i+1 }}</td>
                             <td>{{ item?.institute ?? "N/A" }}</td>
                             <td>{{ item?.university ?? "N/A" }}</td>
                             <td>{{ item?.stream ?? "N/A" }}</td>
                             <td>{{ item?.level ?? "N/A" }}</td>
                             <td>{{ item?.specialisation ?? "N/A" }}</td>
                             <td>{{ item?.completion ?? "N/A" }}</td>
                             <td class="text-center d-flex">
                                 <button
                                 (click)="getEditEducation(item)"
                                 class="btn button_action me-2"
                                 >
                                 <i class="bi bi-pencil-fill"></i>
                                </button>
                                <!-- <button *ngIf="item.education_file_image"
                                (click)="singleViewImage(item.education_file_image)"
                                class="d-block buttonstyle me-2"
                                >
                                <i class="bi bi-eye-fill"></i>
                            </button> -->
                            
                        </td>
                    </tr>
                  </ng-container>
                  <ng-template #noTableFound>
                        <tr>
                            <td colspan="100%" class="text-center">{{translations?.noEducationDataFound}}</td>
                        </tr>
                </ng-template>
                </tbody>
              </table>
           </div>
        </div>
          <div class="mt-3">
             <button type="submit" class="btn button-solide" (click)="formType = 'accident'">Next</button>
          </div>
        </div>
    </ng-container>

    <ng-container *ngIf="formType === 'accident'">
        <div class="p-15">
            <h5 class="mb-4" >{{translations?.empAccidentDetails}}</h5>
            <div class="row">
                  <form [formGroup]="employeeAccidentForm" id="accident-submit-section">
            <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                <label>
                {{translations?.empAccidentDetails}}&nbsp;
                <span *ngIf="Global.isValidationAvailable(employeeAccidentForm, 'accident_type', 'required')" class="text-danger">*</span></label>
                <input class="form-control" type="text" formControlName="accident_type">

                <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAccidentForm, 'accident_type', 'required')">{{translations?.reqField}}</p>
              </div>
          </div>

            <div class="col-md-4 sameasfirst">
                <div class="form-group file-upload uploadfile">
            <label class="">
                {{translations?.accidentFileImage}}&nbsp;
                <span *ngIf="Global.isValidationAvailable(employeeAccidentForm, 'accident_file_image', 'required')" class="text-danger">*</span></label>
                    <input class="form-control" type="file" accept="image/*" formControlName="accident_file_image" (change)="onFileChanged($event, employeeAccidentForm, 'accident_file_image', 'accident_file_image_file')">

                    <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAccidentForm, 'accident_file_image', 'required')">{{translations?.reqField}}</p>

                    <p class="error-element" *ngIf="employeeAccidentForm.get('accident_file_image')?.hasError('fileSize')">
                        {{translations?.fileSize}} {{ employeeAccidentForm.get('accident_file_image')?.getError('maxSize') }} KB.</p>
                        <span *ngIf="!accident_filename">{{translations?.supportedFiles}}</span>
                        <span *ngIf="accident_filename">{{accident_filename}}</span>

            </div>
            </div>

            <div class="col-md-8">
                <div class="form-group position-relative mb-2 mt-2">
             <label>
                {{translations?.description}}&nbsp;
                <span *ngIf="Global.isValidationAvailable(employeeAccidentForm, 'description', 'required')" class="text-danger">*</span></label>
                <textarea class="form-control" type="textarea" style="height:100px;" formControlName="description"></textarea>

                <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAccidentForm, 'description', 'required')">{{translations?.reqField}}</p>
            </div>
           </div>
            <div class="col-md-8">
                <div class="form-group position-relative mb-2 mt-2">
               <label>
                {{translations?.comment}}&nbsp;
                <span *ngIf="Global.isValidationAvailable(employeeAccidentForm, 'comments', 'required')" class="text-danger">*</span></label>
                <textarea class="form-control" type="textarea" style="height:100px;" formControlName="comments"></textarea>

                <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeAccidentForm, 'comments', 'required')">{{translations?.reqField}}</p>
              </div>
            </div>
        </div>

        <div class="row no-gutters bd-1">
            <div class="col-12 col-sm-12">
                <button type="button" class="btn button-solide gray-btn me-2" (click)="cancelEntry()">{{translations?.cancel}}</button>
                <button type="submit" class="btn button-solide me-2" (click)="updateEmployeeAccidentDetails($event)">
                <span >{{translations?.updateAccident}}</span>
             
                </button>
            </div>
        </div>
         </form>
           </div>
       <div class="table-responsive table-left mt-3">
        <table class="table table-bordered shift-allowance mb-0">
            <tbody>
                <tr>
                    <td style="background-color: #F5F6FA; width: 5%; text-align: left;">{{translations?.sl}}</td>
                    <td style="background-color: #F5F6FA; width: 5%; text-align: left;">{{translations?.accidentType}}</td>
                    <td style="background-color: #F5F6FA; width: 5%; text-align: left;">{{translations?.description}}</td>
                    <td style="background-color: #F5F6FA; width: 5%; text-align: center;">{{translations?.comments}}</td>
                    <td style="background-color: #F5F6FA; width: 5%; text-align: center;">{{translations?.action}}</td>
                </tr>
            <ng-container *ngIf="employee_details?.emp_det?.accident?.length > 0; else noTableFound">

                    <tr *ngFor="let item of employee_details?.emp_det?.accident; let i = index">
                        <td scope="row">{{ i+1 }}</td>
                        <td>{{ item.accident_type }}</td>
                        <td>{{ item.description ? item.description : 'N/A' }}</td>
                        <td>{{ item.comments ? item.comments : 'N/A' }}</td>
                        <td class="text-center d-flex">
                            <button
                            (click)="getEditAccident(item)"
                        class="btn button_action me-2"
                      >
                        <i class="bi bi-pencil-fill"></i>
                      </button>
                      <!-- <button *ngIf="item.accident_file_image"
                      (click)="singleViewImage(item.accident_file_image)"
                        class="d-block buttonstyle me-2"
                      >
                        <i class="bi bi-eye-fill"></i>
                      </button> -->
                         
                        </td>
                    </tr>
                </ng-container>
                    <ng-template #noTableFound>
                            <tr>
                                <td  colspan="100%" class="text-center">{{translations?.noAccident}}</td>
                            </tr>
                    </ng-template>
                </tbody>
        </table>
        
       </div>
         <div class="mt-3">
           <button type="submit" class="btn button-solide" (click)="formType = 'training'">Next</button>
         </div>
       </div>
    </ng-container>
    <ng-container *ngIf="formType === 'training'"> 
      <div class="p-15">
        <h5 class="mb-4" >{{translations?.empTraining}}</h5>
        <div class="row">
               <form [formGroup]="employeeTrainingForm" id="training-submit-section">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">

                        <label >
                         {{translations?.trainingType}}&nbsp;
                         <span *ngIf="Global.isValidationAvailable(employeeTrainingForm, 'training_type', 'required')" class="text-danger">*</span>
                        </label>
                        <input class="form-control" type="text" formControlName="training_type">

                         <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeTrainingForm, 'training_type', 'required')">{{translations?.reqValid}}</p>
                   </div>
                 </div>

                  <div class="col-md-4">

            <div class="form-group file-upload uploadfile">
            <label >
                {{translations?.trainingFileImage}}&nbsp;
                <span *ngIf="Global.isValidationAvailable(employeeTrainingForm, 'training_file_image', 'required')" class="text-danger">*</span>
            </label>
                    <input class="form-control" type="file"
                    
                    accept="image/*" formControlName="training_file_image" (change)="onFileChanged($event, employeeTrainingForm, 'training_file_image', 'training_file_image_file')">
                    <!-- <p class="error-element" *ngIf="(max_upload_limit>0 && net_uploaded_size>max_upload_limit)">{{translations?.maxFile}}</p> -->
                    <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeTrainingForm, 'training_file_image', 'required')">{{translations?.reqValid}}</p>
                    <p class="error-element" *ngIf="employeeTrainingForm.get('training_file_image')?.hasError('fileSize')">
                        {{translations?.fileSizeExceeds}} {{ employeeTrainingForm.get('training_file_image')?.getError('maxSize') }} KB.</p>
                        <span *ngIf="!training_filname">{{translations?.supportedFiles}}</span>
                        <span *ngIf="training_filname">{{training_filname}}</span>
            </div>
                   </div>
               </div>

        <div class="row">
            <div class="col-md-8">

             <div class="form-group position-relative mb-2 mt-2">
            <label >
                {{translations?.description}}&nbsp;
                <span *ngIf="Global.isValidationAvailable(employeeTrainingForm, 'description', 'required')" class="text-danger">*</span>
            </label>
                <textarea class="form-control" style="height:100px;" type="textarea" formControlName="description"></textarea>

                <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeTrainingForm, 'description', 'required')">{{translations?.reqValid}}</p>
            </div>
        </div>

        <div class="col-md-8">
            <div class="form-group position-relative mb-2 mt-2">
            <label >
                {{translations?.comment}}&nbsp;
                <span *ngIf="Global.isValidationAvailable(employeeTrainingForm, 'comments', 'required')" class="text-danger">*</span>
            </label>
                <textarea class="form-control" style="height:100px;" type="textarea" formControlName="comments"></textarea>

                <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeTrainingForm, 'comments', 'required')">{{translations?.reqValid}}</p>
            </div>
        </div>
        </div>

        <div class="row no-gutters bd-1">
            <div class="col-12 col-sm-12">
                <button type="button" class="btn button-solide gray-btn me-2" (click)="cancelEntry()">{{translations?.cancel}}</button>
                <button type="submit" class="btn button-solide me-2" (click)="updateEmployeeTrainingDetails($event)">
                <span >{{translations?.update}}</span>
          
                </button>
            </div>
        </div>
                </form>
        </div>
    <div class="table-responsive table-left mt-3">
        <table class="table table-bordered shift-allowance">
            <tbody >
                <tr>
                    <td  style="font-size: 13px; font-weight: 400; width: 12%; color: #4E525E; background-color: #F5F6FA;">{{translations?.sl}}</td>
                    <td  style="font-size: 13px; font-weight: 400; width: 12%; color: #4E525E; background-color: #F5F6FA;">{{translations?.trainingType}}</td>
                    <td  style="font-size: 13px; font-weight: 400; width: 12%; color: #4E525E; background-color: #F5F6FA;">{{translations?.description}}</td>
                    <td  style="font-size: 13px; font-weight: 400; width: 12%; color: #4E525E; background-color: #F5F6FA;">{{translations?.comment}}</td>
                    <td  style="font-size: 13px; font-weight: 400; width: 12%; color: #4E525E; background-color: #F5F6FA;" class="text-center">{{translations?.action}}</td>
                </tr>

                <ng-container *ngIf="employee_details?.emp_det?.training?.length > 0; else noTableFound">

                    <tr *ngFor="let item of employee_details?.emp_det?.training; let i = index">
                        <td  scope="row">{{ i+1 }}</td>
                        <td>{{ item.training_type }}</td>
                        <td>{{ item.description ? item.description : 'N/A' }}</td>
                        <td>{{ item.comments ? item.comments : 'N/A' }}</td>
                        <td class="d-flex">
                            <button
                            (click)="getEditTraining(item)"
                        class="btn button_action me-2"
                      >
                        <i class="bi bi-pencil-fill"></i>
                      </button>
                      <!-- <button *ngIf="item.training_file_image"
                      (click)="singleViewImage(item.training_file_image)"
                        class="d-block buttonstyle me-2"
                      >
                        <i class="bi bi-eye-fill"></i>
                      </button> -->
                            
                        </td>
                    </tr>
                </ng-container>
                <ng-template #noTableFound>
                        <tr>
                            <td colspan="6" class="text-center">{{translations?.noTrainingsFound}}</td>
                        </tr>
                </ng-template>
            </tbody>

           
        </table>
       
    </div>
       <div class="mt-3">
           <button type="submit" class="btn button-solide" (click)="formType = 'extra_curricular'">Next</button>
       </div>
      </div>
    </ng-container>

    <ng-container *ngIf="formType === 'extra_curricular'"> 

      <div class="p-15">

        <h5 class="mb-4" >{{translations?.extraDetails}}</h5>
        <div class="p-15">
          <div class="row">
               <form [formGroup]="employeeExtraCurriculumForm" id="extracurriculum-submit-section">
          <div class="row">
              <div class="col-md-4">
                  <div class="form-group">

              <label>
                  {{translations?.extraCurriculumType}}&nbsp;
                  <span *ngIf="Global.isValidationAvailable(employeeExtraCurriculumForm, 'extra_curricular_type', 'required')" class="text-danger">*</span>
              </label>
                  <input class="form-control" type="text" formControlName="extra_curricular_type">

                  <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeExtraCurriculumForm, 'extra_curricular_type', 'required')">{{translations?.reqValid}}</p>
                  <!-- <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeExtraCurriculumForm, 'extra_curricular_type', 'pattern')">Numbers not allowed</p> -->
              </div>
          </div>


              <div class="col-md-4">
                  <div class="form-group file-upload uploadfile">

              <label>
                  {{translations?.extraCurriculumFileImage}}&nbsp;
                  <span *ngIf="Global.isValidationAvailable(employeeExtraCurriculumForm, 'extracurriculum_file_image', 'required')" class="text-danger">*</span>
              </label>
                      <input class="form-control" type="file" accept="image/*" formControlName="extracurriculum_file_image" (change)="onFileChanged($event, employeeExtraCurriculumForm, 'extracurriculum_file_image', 'extracurriculum_file_image_file')">
                      <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeExtraCurriculumForm, 'extracurriculum_file_image', 'required')">{{translations?.reqValid}}</p>
                      <!-- <p class="error-element" *ngIf="(max_upload_limit>0 && net_uploaded_size>max_upload_limit)">{{translations?.maxFile}}</p> -->
                      <p class="error-element" *ngIf="employeeExtraCurriculumForm.get('extracurriculum_file_image')?.hasError('fileSize')">
                          {{translations?.fileExceed}} {{ employeeExtraCurriculumForm.get('extracurriculum_file_image')?.getError('maxSize') }} KB.</p>
                          <span *ngIf="!extraCurrCulum_filename">{{translations?.supportedFiles}}</span>
                          <span *ngIf="extraCurrCulum_filename">{{extraCurrCulum_filename}}</span>
              </div>
          </div>

              <div class="col-md-8">
                  <div class="form-group position-relative mb-2 mt-2">
              <label>
                  {{translations?.description}}&nbsp;
                  <span *ngIf="Global.isValidationAvailable(employeeExtraCurriculumForm, 'description', 'required')" class="text-danger">*</span>
              </label>
                  <textarea class="form-control" type="textarea" style="height:100px;" formControlName="description"></textarea>

                  <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeExtraCurriculumForm, 'description', 'required')">{{translations?.reqValid}}</p>
              </div>
          </div>
              <div class="col-md-8">
                  <div class="form-group position-relative mb-2 mt-2">
              <label>
                  {{translations?.comment}}&nbsp;
                  <span *ngIf="Global.isValidationAvailable(employeeExtraCurriculumForm, 'comments', 'required')" class="text-danger">*</span>
              </label>
                  <textarea class="form-control" type="textarea" style="height:100px;" formControlName="comments"></textarea>

                  <p class="error-element" *ngIf="!Global.isInputRuleValid(employeeExtraCurriculumForm, 'comments', 'required')">{{translations?.reqValid}}</p>
              </div>
          </div>
          </div>

          <div class="row bd-1">
              <div class="col-12 col-sm-12">
                  <button type="button" class="btn button-solide gray-btn me-2" (click)="cancelEntry()">{{translations?.cancel}}</button>
                  <button type="submit" class="btn button-solide me-13" (click)="updateEmployeeExtraCurriculumDetails($event)">
                  <span >{{translations?.update}}</span>
                  </button>
                  
              </div>
          </div>
               </form>
         </div>
      </div>
      <div class="table-responsive table-left mt-3">
          <table class="table table-bordered shift-allowance mb-0">
              <tbody>
                  <tr>
                      <td style="background-color: #F5F6FA; width: 5%; text-align: left;">{{translations?.sl}}</td>
                      <td style="background-color: #F5F6FA; width: 5%; text-align: left;">{{translations?.extraCurriculumType}}</td>
                      <td style="background-color: #F5F6FA; width: 5%; text-align: left;">{{translations?.description}}</td>
                      <td style="background-color: #F5F6FA; width: 5%; text-align: center;">{{translations?.comments}}</td>
                      <td style="background-color: #F5F6FA; width: 5%; text-align: center;">{{translations?.action}}</td>
                  </tr>
                  <ng-container *ngIf="employee_details?.emp_det?.extra_curricular?.length > 0; else noTableFound">
                      <tr *ngFor="let item of employee_details?.emp_det?.extra_curricular; let i = index">
                          <td scope="row">{{ i+1 }}</td>
                          <td>{{ item.extra_curricular_type }}</td>
                          <td>{{ item.description ? item.description : 'N/A' }}</td>
                          <td>{{ item.comments ? item.comments : 'N/A' }}</td>
                          <td class="text-center d-flex">
                              <button
                              (click)="getEditExCurr(item)"
                          class="btn button_action me-2"
                        >
                          <i class="bi bi-pencil-fill"></i>
                        </button>
                        <!-- <button *ngIf="item.extracurriculum_file_image"
                        (click)="singleViewImage(item.extracurriculum_file_image)"
                          class="d-block buttonstyle me-2"
                        >
                          <i class="bi bi-eye-fill"></i>
                        </button> -->
                            
                          </td>
                      </tr>
                  </ng-container>
                  <ng-template #noTableFound>
                          <tr>
                              <td colspan="6" class="text-center">{{translations?.noData}}</td>
                          </tr>
                  </ng-template>
              </tbody>

             
          </table>
      </div>
        <div class="mt-3">
          <button type="submit" class="btn button-solide" (click)="isFormPending = false">Submit Form</button>
       </div>
      </div>
    </ng-container>
    </div>
    <div *ngIf="!isLinkValid && !isLoading" class="text-center">
      <h2 class="mt-5 pt-5">This Invite link is Expired!</h2>
    </div>
    <div *ngIf="!isFormPending" class="text-center">
      <h2 class="mt-5 pt-5">Thanks for filling out our form!</h2>
    </div>
  </div>         
