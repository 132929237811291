

<div class="p-15 modal-header bg-light border-bottom">
    <div class="modal-text">
        <h3 class="derktext left-line static-text mb-0">{{translations?.viewImage}}</h3>
    </div>
    <button class="btn-close btn ms-4 ms-auto" (click)="closeDialog()"><i class="bi bi-x-lg"></i></button>
</div>
<div class="p-15 modal-body border-bottom m">
    <div class="risection">
        <div class="d-flex justify-content-center">
            <iframe class="pdf" style="border: none; overflow: hidden;" [src]="imagePath" width="700" [style.height.px]="500" [style.overflow-x]="'hidden'" [style.overflow-y]="'hidden'" height="500"></iframe>
        </div>
    </div>
</div>