import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.development';
import * as Global from 'src/app/shared/globals';
import { LanguageTranslateService } from './services/language-translate.service';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  Global = Global;
  PageMainTitle = environment.APPNAME;
  lang!: string;
  private com_userSubj = new BehaviorSubject<any>(null);
  com_user$ = this.com_userSubj.asObservable();
  constructor(
    private authService: AuthService,
    private renderer: Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private langService: LanguageTranslateService
  ) { }

  setData(newData: any) {
    this.com_userSubj.next(newData);
  }

  ngOnInit(): void {
    this.router.events.subscribe(async (event: Event) => {
      if (event instanceof NavigationStart) {
        const url = event.url.split('/');
        const langParam = url[1];
        if (langParam === 'en' || langParam === 'ar') this.lang = langParam;
        else this.lang = this.langService.getLangLocalStorage() ?? 'en';
        await this.loadStylesheet(this.lang);
        this.langService.setLangLocalStorage(this.lang);
        if (langParam !== this.lang) {
          let newUrl!: string;
          if (url.slice(2).length !== 0) {
            newUrl = [this.lang, ...url.slice(2)].join('/');
          } else {
            newUrl = [this.lang, 'dashboard'].join('/');
          }
          this.router.navigateByUrl(newUrl);
        }
      }
      if (event instanceof NavigationEnd) {
        if (this.authService.isCompanyUserLoggedIn()) {    
          // this.fetchCompanyUserAuthDetails();
          let user = localStorage.getItem('hrmlix-ind-companyuser-user');
          if (user && JSON.parse(user).user_type == 'staff') {
            this.fetchCompanyStaffAuthDetails();
          } else {
            
            this.fetchCompanyUserAuthDetails();
          }
        }
      }
      const rt = this.getActivatedRouteChild(this.activatedRoute);
      rt.data.subscribe((data: any) => {
        if (data?.pageEngTitle) {
          if (this.lang == 'en') {
            this.titleService.setTitle(data?.pageEngTitle + ' | ' + this.PageMainTitle);
          } else {
            this.titleService.setTitle(data?.pageArTitle + ' | ' + this.PageMainTitle  );
          }
        } else {
          this.titleService.setTitle(this.PageMainTitle);
        }
      });
    });    
   
  }
  getActivatedRouteChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getActivatedRouteChild(activatedRoute?.firstChild);
    } else {
      return activatedRoute;
    }
  }
  fetchCompanyUserAuthDetails(getObj: any = false) {
    return new Promise((resolve, reject) => {
      this.authService.getCompanyUserAccountDetails().then(res => {
        if (res?.status == 'success') {
          res.company_det.user_type = "company";
          this.authService.setComDetails(res);
          if (!!localStorage.getItem('payment-required')) localStorage.removeItem('payment-required');
          this.setData(res?.company_det?.com_det);
          localStorage.setItem('hrmlix-ind-companyuser-user', JSON.stringify(res?.company_det));
          localStorage.setItem('hrmlix-ind-companyuser-details', JSON.stringify(res?.company_det.com_det));
          localStorage.setItem('hrmlix-ind-companyuser-permission', JSON.stringify(res?.company_det?.package));
          this.authService.setComLogo(res?.company_det?.com_logo);
          if (getObj == true) {
            resolve(res?.company_det);
          } else {
            resolve(true);
          }
        }

        if (res?.company_det?.status == 'deactivated') {
          // localStorage.clear();
          const lang = this.langService.getLangLocalStorage() ?? 'en';
          localStorage.removeItem('hrmlix-ind-companyuser-token');
          localStorage.removeItem('hrmlix-ind-companyuser-user');
          localStorage.removeItem('hrmlix-ind-companyuser-details');
          localStorage.removeItem('hrmlix-ind-companyuser-permission');
          this.router.navigate([`${lang}/login`]);
        }
      });
    });
  }
  fetchCompanyStaffAuthDetails() {
    return new Promise((resolve, reject) => {
      this.authService.getCompanyStaffAccountDetails().then(async (res: any) => {
        if (res?.status == 'success') {
          res.user_data.user_type = "staff";
          let companyDetails: any = await this.fetchCompanyUserAuthDetails(true);
          if (companyDetails) {
            res.user_data.credit_stat = companyDetails?.credit_stat;
            res.user_data.com_logo = companyDetails?.com_logo ?? null;
          }
          this.setData(res?.user_data);
          localStorage.setItem('hrmlix-ind-companyuser-user', JSON.stringify(res?.user_data));
          localStorage.setItem('hrmlix-ind-companyuser-details', JSON.stringify(res?.user_data));
          localStorage.setItem('hrmlix-ind-companyuser-permission', JSON.stringify(res?.permission));

          resolve(true);
        }
      });
    });
  }
  loadStylesheet(language: string): void {
    const existingLink = document.querySelector(`link[href*="styles-ar.css"]`);

    if (existingLink) {
      if (language == 'en') {
        existingLink.remove();
      } else {
        return;
      }
    }

    if (language == 'ar') {
      const link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'stylesheet');
      this.renderer.setAttribute(link, 'type', 'text/css');
      this.renderer.setAttribute(link, 'href', `assets/styles/styles-${language}.css`);

      this.renderer.appendChild(document.head, link);
      return;
    }
  }
}
