<app-menu-sidebar></app-menu-sidebar>
<div class="app-content leave-cms">
    <div class="app-header topfinance-nav">
        <ul>
            <li [class.active]="isActive('apply-revision')" class="static-text"><a (click)="routerChange('apply-revision')">{{translations?.applyRevision}}</a></li>
            
            <li [class.active]="isActive('calculate-arrear')" class="static-text"><a (click)="routerChange('calculate-arrear')">{{translations?.calculateRevision}}</a></li>
            <li [class.active]="isActive('revision-report')" class="static-text"><a (click)="routerChange('revision-report')">{{translations?.revisionReport}}</a></li>
            <li [class.active]="isActive('arrear-slip')" class="static-text"><a (click)="routerChange('arrear-slip')">{{translations?.arrearSlip}}</a></li>

            <!-- <li [class.active]="isActive('over-time')" class="static-text"><a (click)="routerChange('over-time')">{{translations?.overtime}} </a></li> -->
        </ul>
        
        <!-- <app-language></app-language> -->
        <app-profile></app-profile>
    </div>
    <router-outlet></router-outlet>
</div>