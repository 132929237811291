import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';
import * as Global from 'src/app/shared/globals';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Response } from '../http-handeler/interfaces/response'
@Injectable({
  providedIn: 'root'
})
export class WebService {
  readonly BASE_URL!:string;
  readonly BASE_HREF!:string;
  constructor(
    private http:HttpClient
  ) 
  { 
    this.BASE_URL = environment.apiUrl;
    this.BASE_HREF = environment.BASEHREF;
  }

  get(url:string){
    return this.http.get<any>(`${this.BASE_URL}/${this.BASE_HREF} /${url}`)
  }

  async post(url:string, payload:any){
    return await firstValueFrom(
      this.http.post<any>(`${this.BASE_URL}/${this.BASE_HREF}/${url}`, payload)
    ).then((response:Response)=>{
      if (response?.status !== 'success') {
        if (response?.val_msg) {
          const {val_msg} = response;
          for (const key in val_msg) {
            if (Object.prototype.hasOwnProperty.call(val_msg, key)) {
              const element:any = val_msg[key];
              if (element.message) {
                return this.handleError(
                  element.message || 'Something went wrong!'
                )
              }
              
            }
          }
          return 'Validation Error: Please check all the fields correctly';
        }
        return this.handleError(
          response?.error || response?.message || response?.code || 'Something went wrong!'
        );
      }
      return response;
    }).catch(this.handleError)
  }
  postFormData(url: string, payload: any) {
    var formData = new FormData();
    for (var key in payload) {
      formData.append(key, payload[key]);
    }
    // return this.http.post<any>(`${this.Base_Url}/${url}`, formData);
    return this.post(url, formData);
  }
  private handleError(error: any): Promise<any> {
    if (error.status === 0) {
      return Promise.reject<string>('There is no internet connection');
    }
    if (error.status) {
      if (error.status === 401) {
        localStorage.clear();
        // window.location.href = '/';
        return Promise.reject<string>(error.statusText + '! No user found with your token');
      }
      if (error.status === 403) {
        localStorage.clear();
        // window.location.href = '/';
        return Promise.reject<string>('Your are logged Out');
      }
      return Promise.reject<string>(error?.error?.message);
    }
    // if (
    //   (error.message) ||
    //   error == 'No user found with your token'
    // ) {
    //   localStorage.clear();
    //   window.location.href = '/';
    // }
    return Promise.reject<string>(error.message || error);
  }
  simpleGet(url: string, payload: Object = {}) {
    return this.http.post(`${this.BASE_URL}/${this.BASE_HREF}/${url}`, payload, { responseType: 'blob', observe: 'response' });
  }
  postFormDataNested(uri: string, payload: any) {
    var formData = new FormData();
    for (var key in payload) {
     
      if(key == 'eighty_c_investments') {
        let i=0;
        let rawElemt=payload[key];
        payload[key]=payload[key].filter((x:any) =>x.children.length>0)
        for(let index in  payload[key])
        {
          let p_head=rawElemt.find((x:any) => x.name==payload[key][index].parent_id)
          formData.append(`declaration_p_head[${index}]`,p_head?.label);
          formData.append(`declaration_items[${index}]`,payload[key][index].label);
          formData.append(`declaration_key[${index}]`,payload[key][index].name);
          payload[key][index].children.forEach((element:any,SubIndex:any) => {
          formData.append(`declaration_sub_label[${index}][${SubIndex}]`,element.label);
          formData.append(`declaration_sub_amount[${index}][${SubIndex}]`,element.amount ?? 0);
          if(element.document_file!=null)
          {
            element.document_file?.forEach((subElement:any,subsubIndex:number) =>{
              formData.append(`declaration_sub_document[${index}][${SubIndex}][${subsubIndex}]`,subElement);  
            });
          }  

          if(element.pre_sub_declaration_document!=null)
          {
            element.pre_sub_declaration_document?.forEach((subElement:any,subsubIndex:number) =>{
              formData.append(`pre_sub_declaration_document[${index}][${SubIndex}][${subsubIndex}]`,subElement.file);  
            });
          }  
          
          });
         
        } 
      }
     
      else if(key == 'rantal_house_documents') {

        for (let i in payload[key])
      {
        formData.append(`rantal_house_documents[${i}]`,payload[key][i]);

      }

      }

      else if(key == 'other_income_document') {
      for (let i in payload[key])
      {
        formData.append(`other_income_document[${i}]`,payload[key][i]);

      }

      }


     else{
      formData.append(key, payload[key]);

     }

    }

    return this.http.post<any>(`${this.BASE_URL}/${this.BASE_HREF}/${uri}`, formData);
  }
}
