import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';

@Component({
  selector: 'app-view-doc',
  templateUrl: './view-doc.component.html',
  styleUrls: ['./view-doc.component.css']
})
export class ViewDocComponent implements OnInit {

  imagePath:string = '';
  lang:any="en"
  currentLanguage$?: Subscription;
  translations:any;
  constructor(
    private dialogRef:MatDialogRef<ViewDocComponent>,
    private langService: LanguageTranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
     this.imagePath = data;
    //  console.log(this.imagePath,'image path');
     
  }

  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      this.lang = this.langService.getLangLocalStorage();
            return new Promise<boolean>(async (resolve, reject) => {
              await this.langService.loadTranslations('home', 'AnnouncementListComponent')?.then((res: any) => {
                this.translations = res;
                resolve(true);
              }).catch((err: any) => {
                console.error(`Failed to load translations`, err);
                reject(false);
              });
            })
    });
  }
  closeDialog(){
    this.dialogRef.close();
  }
}
