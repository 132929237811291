
<app-menu-sidebar></app-menu-sidebar>
<div class="app-content leave-cms">
  <div class="app-header topfinance-nav ">
    <ul>
      <li [class.active]="isActive('company')"><a (click)="routerChange('company')">{{translations?.companyProfile}}</a></li>
      <li [class.active]="isActive('staff')"><a (click)="routerChange('staff')">{{translations?.staffMembers}}</a></li>
      <li [class.active]="isActive('role-management')"><a (click)="routerChange('role-management')">{{translations?.roleBaseAccess}}</a></li>
      <li [class.active]="isActive('master')"><a (click)="routerChange('master')">{{translations?.masterSettings}}</a></li>
      <li [class.active]="isActive('government-rules')"><a (click)="routerChange('government-rules')">{{translations?.governmentRules}}</a></li>
      <li [class.active]="isActive('company-rules')"><a (click)="routerChange('company-rules')">{{translations?.companyRules}}</a></li>
    </ul>

    <!-- <app-language></app-language> -->
    <app-profile></app-profile>
  </div>

    <router-outlet></router-outlet>
</div>