import {Component,ElementRef,OnInit, ViewChild} from '@angular/core';
import {FormBuilder,FormControl,FormGroup,Validators,} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, debounceTime } from 'rxjs';
import PaginationOptions from 'src/app/models/PaginationOptions';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-announcement-details',
  templateUrl: './announcement-details.component.html',
  styleUrls: ['./announcement-details.component.css']
})
export class AnnouncementDetailsComponent implements OnInit {
  @ViewChild('myInput') myInput!: ElementRef;
  
  Global = Global;
  currentLanguage$?: Subscription;
  lang: any = 'en';
  translations:any;
  replyForm: FormGroup;
  announceSingleData:any;
  announceReplyData:any[] = [];
  announcement_id!:string;
  showComments:boolean = false;
  constructor(
    private announceServ: AnnouncementService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private langService: LanguageTranslateService,
    private router: Router,
    private activatedRoute:ActivatedRoute
  ){
    this.replyForm = this.formBuilder.group({
      reply_description: [null, Validators.compose([])],
    });

    this.activatedRoute.params.subscribe(
      params => this.announcement_id = params['annc_id'] ?? null
    )
    
  }

  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(
      async () => {
        this.lang = this.langService.getLangLocalStorage();
        return new Promise<boolean>(async (resolve, reject) => {
          await this.langService
            .loadTranslations('home', 'AnnouncementListComponent')
            ?.then((res: any) => {
              this.translations = res;
              resolve(true);
            })
            .catch((err: any) => {
              console.error(`Failed to load translations`, err);
              reject(false);
            });
        });
      }
    );
    this.getSingleAnnouncement();
     this.getAnnounceMentReply();
  }

  getSingleAnnouncement(){
   return new Promise((resolve,reject)=>{
      let payload:any = {
        status:"published",
        announcement_id:this.announcement_id
      }
      this.spinner.show();
     this.announceServ.getAnnouncementDetails(payload).then((res:any)=>{
           if (res?.status === 'success') {
              this.announceSingleData = res?.result[0] ?? {};
           }
           this.spinner.hide();
     }).catch((err:any)=>{
       this.toastr.error(err)
       this.spinner.hide();
     })
    })
  }
  getAnnounceMentReply(){
    return new Promise((resolve,reject)=>{
       this.announceServ.getReplyAnnouncements({pageno:1,parent_id:this.announcement_id}).then((res:any)=>{
          if (res?.status === 'success') {
            this.announceReplyData = res?.data ?? [];
          }
       })
    }).catch((err:any)=>{
      // console.log(err);
    })
  }
  createReply() {
    return new Promise((resolve, reject) => {
      this.announceServ
        .createReply({
          parent_id: this.announcement_id,
          reply_description: this.replyForm.value.reply_description,
        })
        .then((res: any) => {
          if (res?.status === 'success') {
            
            if (this.showComments) {
             this.showComments = !this.showComments;
             this.focusOnInput();
            }
            this.replyForm.reset();
            this.getAnnounceMentReply();
            resolve(true);
          }
         
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  viewComments(){
    this.showComments = !this.showComments;
  }
  routeToAllAnnc(){
    this.router.navigate([this.lang,'announcement-list'])
  }
  focusOnInput(){
    setTimeout(()=>{
      this.myInput.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
       this.myInput.nativeElement.focus();
    },200)
   
  }
}
