<app-menu-sidebar></app-menu-sidebar>
<div class="app-content leave-cms">

    <div class="app-header topfinance-nav">
        <ul>
            <li [class.active]="isActive('employee-listing')" class="static-text" (click)="routerChange('employee-listing')"><a >{{translations?.employees}}</a></li>
            <!-- <li [class.active]="isActive('add')"  class="static-text" (click)="routerChange('add')" ><a >{{translations?.addEmployee}}</a></li> -->
            <li [class.active]="isActive('layoffs')" class="static-text"  (click)="routerChange('layoffs')"><a >{{translations?.layoffs}}</a></li>
        </ul>

        <!-- <app-language></app-language> -->
        <app-profile></app-profile>
    </div>
    <router-outlet></router-outlet>
</div>