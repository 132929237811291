import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent {
  lang:any="en"
  currentLanguage$?: Subscription
  translations: any;
  constructor(
    private authService: AuthService,
    private langService: LanguageTranslateService,
  ){

  }
  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      this.lang = this.langService.getLangLocalStorage();
        return new Promise<boolean>(async (resolve, reject) => {
          await this.langService.loadTranslations('layout', 'PermissionComponent')?.then((res: any) => {
            this.translations = res;
            resolve(true);
          }).catch((err: any) => {
            console.error(`Failed to load translations`, err);
            reject(false);
          });
        })
      });
  }
  ngOnDestroy() {
    this.currentLanguage$?.unsubscribe();
  }
}
