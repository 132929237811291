
<div class="app-content leave-cms">
    <app-menu-sidebar></app-menu-sidebar>
    
    <div class="info-fluid">
      <div class="app-header justify-content-end opfinance-nav">

        <!-- <app-language class="ms-auto"></app-language> -->
        <app-profile></app-profile>
        
    </div>
      <div class="card info_box">
      <div class="p-15 border-buttom">
        <h5 class="mb-0">{{translations?.notice}}</h5>
      </div>      
        <div class="table-responsive table-left">
            <div class="p-15">
                <h6 class="mb-0">{{translations?.expiredDocuments}}</h6>
            </div>
            <table class="table table-bordered shift-allowance">
              <tbody>
  
                <tr>
                  <td style="background-color: #F5F6FA; width: 12%; text-align: left;">{{translations?.employeeName}}</td>
                  <td style="background-color: #F5F6FA; width: 12%; text-align: left;">{{translations?.doc}}</td>
                  <td style="background-color: #F5F6FA; width: 12%; text-align: left;">{{translations?.expiredDate}}</td>
                  <td style="background-color: #F5F6FA; width: 12%; text-align: center;">{{translations?.action}}</td>
                </tr>
  
                <tr *ngFor="let notice of noticeExpiredList">
                    <td style="text-align: center;">
                       
                          
                        <div class="user_dtls d-flex align-items-center cursor" (click)="routeTonoticeDetails(notice?.emp_db_id)">
                          <figure *ngIf="notice?.profile_pic; else noImg">
                            <img [src]="notice?.profile_pic" alt="user">
                          </figure>
                          <ng-template #noImg>
                            <figure>
                                <img src="assets/images/user.jpg" alt="user">
                            </figure>
                          </ng-template>
                            <figcaption>
                                
                            <h3 >{{notice?.emp_first_name}} {{notice?.emp_last_name}}</h3>
                            <h5>{{notice?.emp_id}}</h5>
                            </figcaption>
                        </div>
                   
                  </td>
                    <td style="text-align: left;">{{notice?.document_name}}</td>
                    <td style="text-align: left;">{{Global.getDateSlashFormat(notice?.valid_to)}}</td>
                    <td style="text-align: center;">
                        <div class="d-flex justify-content-center">
                            <button (click)="viewDoc(notice?.file)" class="btn button_action blue_action"><i class="bi bi-eye-fill"></i></button>
                        </div>
                    </td>
                
                </tr>
                <tr *ngIf="noticeExpiredList?.length == 0">
                    <td colspan="100%" style="text-align: center;">{{translations?.nodata}}</td>
                 </tr>              
  
              </tbody>
          </table>
      </div>
        <app-custom-pagination [rows]="noticeExpiredList" [paginationOptions]="paginationOptionsExpired" (onPageClicked)="getNoticeExpiring({'page':$event})"></app-custom-pagination>

    </div>
</div>

<div class="info-fluid">
    <div class="card info_box">
      <div class="p-15 pb-0">
        <!-- <h5 class="mb-4">Notice</h5> -->
      </div>      
        <div class="table-responsive table-left">
            <div class="p-15 pb-0">
                <h6 class="mb-4">{{translations?.expiringDocuments}}</h6>
            </div>
            <table class="table table-bordered shift-allowance">
              <tbody>
  
                <tr>
                  <td style="background-color: #F5F6FA; width: 12%; text-align: left;">{{translations?.employeeName}}</td>
                  <td style="background-color: #F5F6FA; width: 12%; text-align: left;">{{translations?.doc}}</td>
                  <td style="background-color: #F5F6FA; width: 12%; text-align: left;">{{translations?.expireDate}}</td>
                  <td style="background-color: #F5F6FA; width: 12%; text-align: center;">{{translations?.action}}</td>
                </tr>
  
                <tr *ngFor="let notice of noticeExpiringList">
                    <td style="text-align: center;"> 
                            <div class="user_dtls d-flex align-items-center cursor" (click)="routeTonoticeDetails(notice?.emp_db_id)">
                              <figure *ngIf="notice?.profile_pic; else noImg">
                                <img [src]="notice?.profile_pic" alt="user">
                              </figure>
                              <ng-template #noImg>
                                <figure>
                                    <img src="assets/images/user.jpg" alt="user">
                                </figure>
                              </ng-template>
                                <figcaption>                                    
                                  <h3>{{notice?.emp_first_name}} {{notice?.emp_last_name}}</h3>
                                  <h5>{{notice?.emp_id}}</h5>
                                </figcaption>
                            </div>
                      </td>
                    <td style="text-align: left;">{{notice?.document_name}}</td>
                    <td style="text-align: left;">{{Global.getDateSlashFormat(notice?.valid_to)}}</td>
                    <td style="text-align: center;">
                        <div class="d-flex justify-content-center">
                            <button class="btn button_action blue_action" (click)="viewDoc(notice?.file)"><i class="bi bi-eye-fill"></i></button>
                        </div>
                    </td>
                
                </tr>

                 <tr *ngIf="noticeExpiringList?.length == 0">
                    <td colspan="100%" style="text-align: center;">{{translations?.nodata}}</td>
                 </tr>

                                    
  
              </tbody>
          </table>
      </div>
      <app-custom-pagination [rows]="noticeExpiringList" [paginationOptions]="paginationOptionsExpiring" (onPageClicked)="getNoticeExpiring({'page':$event})"></app-custom-pagination>

    </div>
</div>
</div>