import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AttendanceComponent } from './pages/attendance/attendance.component';
import { PayrollsComponent } from './pages/payrolls/payrolls.component';
import { MatIconModule } from '@angular/material/icon';
import { AuthenticationInterceptor } from './core/interceptors/authentication.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NotfoundPageComponent } from './pages/notfound-page/notfound-page.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LeaveModule } from './pages/leave/leave.module';
import { LeaveComponent } from './pages/leave/leave.component';
import { SharedModule } from './shared/shared.module';
import { CommonModule, DatePipe } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { NgChartsModule } from 'ng2-charts';
import { AnnouncementListComponent } from './pages/miscellaneous/announcement-list/announcement-list.component';
import { NoticeListComponent } from './pages/miscellaneous/notice-list/notice-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ViewDocComponent } from './pages/miscellaneous/modal/view-doc/view-doc.component';
import { PurchaseCreditDialogComponent } from './pages/home/purchase-credit-dialog/purchase-credit-dialog.component';
import { CreditPurchaseHistoryComponent } from './pages/home/credit-purchase-history/credit-purchase-history.component';
import { AnnouncementDetailsComponent } from './pages/miscellaneous/announcement-details/announcement-details.component';

// import { NgChartsModule } from 'ng2-charts';


@NgModule({
  declarations: [
    AppComponent,
    // MenuSidebarComponent,
    AttendanceComponent,
    LeaveComponent,
    PayrollsComponent,
    NotfoundPageComponent,
    HomeComponent,
    AnnouncementListComponent,
    NoticeListComponent,
    ViewDocComponent,
    PurchaseCreditDialogComponent,
    CreditPurchaseHistoryComponent,
    AnnouncementDetailsComponent,
  
  
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FullCalendarModule,
    MatIconModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    NgChartsModule,
    ToastrModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    DatePipe,
    {
      provide:HTTP_INTERCEPTORS, useClass:AuthenticationInterceptor, multi:true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


